import {
  OuterBlockHeader,
  HeaderLogoWrapper,
  LogoLangWrapper,
  LogoBrandHeader,
  StyledCurrentLanguageIcon,
} from "../../styles";
import { useLogo } from "../../services/hooks";
import { i18n, state } from "../../services";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import LanguageModal from "../Modals/LanguageModal";

const CryptoHeader = ({ onChangeLanguage }) => {
  const { setModalData } = useContext(ContextUI);

  const options = state.get().options;
  const logoIcon = useLogo(options?.theme);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: <LanguageModal onChange={onChangeLanguage} />,
      title: "chooseLanguage",
      isFullView: i18n.getLanguageConfig()?.length > 4,
    });
  };

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper>
        <LogoLangWrapper $hasLogo={logoIcon}>
          <LogoBrandHeader src={`${logoIcon}`} />
          <StyledCurrentLanguageIcon
            onClick={handleOpen}
            src={`/flags/${i18n.getCountryCode()?.toString()?.toLowerCase()}.svg`}
          />
        </LogoLangWrapper>
      </HeaderLogoWrapper>
    </OuterBlockHeader>
  );
};

export default CryptoHeader;
