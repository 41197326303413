import {
  BlockDetailsWrapper,
  DetailsTransactionTitle,
  IconColorWrapper,
} from "../../styles";
import IconCopy from "../../assets/icons/iconCopy";
import { ChallengeMainTitle } from "../Challenge/style";
import PaymentMethodsLogo from "../PaymentMethods/PaymentMethodsLogo";
import Trans from "../Trans";

const BankInfoDetails = ({
  title,
  value,
  copyMessage,
  handleCopy,
  bankNameIcon,
}) => {
  if (!value) {
    return null;
  }

  return (
    <BlockDetailsWrapper data-transaction-name={`bank-info-${title}`}>
      <DetailsTransactionTitle>
        <Trans message={title} />
      </DetailsTransactionTitle>
      <span
        style={{ display: "flex", ...(bankNameIcon && { alignItems: "end" }) }}
      >
        <DetailsTransactionTitle
          style={{ marginRight: "8px", fontWeight: "600" }}
        >
          <ChallengeMainTitle bankNameIcon={bankNameIcon}>
            {bankNameIcon && (
              <PaymentMethodsLogo selectedMethod={bankNameIcon} />
            )}
            <span data-transaction-name={`bank-info-value-${title}`}>
              {value}
            </span>
          </ChallengeMainTitle>
        </DetailsTransactionTitle>
        <span
          onClick={() => handleCopy(value, copyMessage)}
          style={{ cursor: "pointer", color: "blue" }}
          data-transaction-name={`CopyValue-${title}`}
        >
          <IconColorWrapper>
            <IconCopy />
          </IconColorWrapper>
        </span>
      </span>
    </BlockDetailsWrapper>
  );
};

export default BankInfoDetails;
