const MIN_NAME_LENGTH = 2;
const MIN_LAST_NAME_LENGTH = 2;
const MAX_LENGTH = 50; // first name + last name

const nameRegex = /^[A-Za-z'-]{2,}( [A-Za-z'-]{2,})*$/;

const preprocessValue = (value) => value?.trim()?.replace(/\s+/g, " ");

const validateNameForKombine = (value) => {
  const trimmedValue = preprocessValue(value);
  const parts = trimmedValue?.split(" ");
  const firstName = parts[0] || "";
  const lastName = parts[1] || "";

  return (
    nameRegex.test(trimmedValue) &&
    parts.length === 2 &&
    firstName.length >= MIN_NAME_LENGTH &&
    lastName.length >= MIN_LAST_NAME_LENGTH &&
    trimmedValue.length <= MAX_LENGTH
  );
};

const validateDefault = (value) => {
  const trimmedValue = preprocessValue(value);

  return (
    nameRegex.test(trimmedValue) &&
    trimmedValue.length >= MIN_NAME_LENGTH &&
    trimmedValue.length <= MAX_LENGTH
  );
};

const validationConfig = {
  default: validateDefault,
  kombine: validateNameForKombine,
};

export const validateCardHolder = (validationType, value) => {
  const validate = validationConfig[validationType];

  if (validate) {
    return validate(value);
  }
};
