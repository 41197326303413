import { InstructionStepDataSubtext } from "../../../components/Instruction/style";
import { api, logger, localStorageService } from "../../../services";
import { IPaymentInstructions } from "./interfaces";

const accountRequisites: IPaymentInstructions = {
  title: "accountRequisitesTitleMakePayment",
  instructionList: [
    {
      title: "accountRequisitesCopyAccountDetails",
      getPaymentData: ({ data = {} }) => data?.account,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account,
        text: "accountRequisitesCopyAccountDetailsCopied",
      }),
    },
    {
      title: "accountRequisitesMakeTransfer",
      getPaymentData: ({ state = {} }) => (
        <>
          <InstructionStepDataSubtext>
            {state?.currency}
          </InstructionStepDataSubtext>{" "}
          {state?.amount}
        </>
      ),
      getCopyInfo: ({ state = {} }) => ({
        copy: state?.amount,
        text: "amountCopy",
      }),
    },
    // TODO: Refactor to use a translation function for handling text localization.
    {
      title: "accountRequisitesPressButton",
      secondaryTitle: {
        text: "paymentConfirmationSubtitleMadePayment",
        bold: true,
      },
    },
  ],
  getInfo: ({ data = {} }) =>
    !!data?.instruction ? "accountRequisitesMadeTransfer" : null,
  getExpirationDate: () => {
    let expirationDate;

    try {
      expirationDate = localStorageService.getSessionData(
        "sessionStorage",
        "expirationDate",
      )?.expirationDate;
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
      return null;
    }

    if (!expirationDate) {
      const expirationTime = new Date(
        Date.now() + 10 * 60 * 1000,
      ).toISOString();

      try {
        localStorageService.setSessionData("sessionStorage", "expirationDate", {
          expirationDate: expirationTime,
        });
      } catch (error) {
        logger.warn("Failed to write to sessionStorage", { error });
      }

      return expirationTime;
    }

    return expirationDate;
  },
  submitAction: api.submitAccountRequisites,
};

export default accountRequisites;
