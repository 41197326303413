import {
  CurrencyImage,
  StyledSvgColoredImage,
  StyledPaymentIconWrapper,
} from "./style";
import ussdIcon from "../../assets/icons/ussdDefaultIcon.svg";
import bankTransferIcon from "../../assets/icons/bankTransferIcon.svg";
import { PAYMENT_METHODS_ICON } from "../../constants/paymentMethods";

const PaymentMethodsLogo = ({
  selectedMethod,
  resize,
  coloredIcon,
  hideIcon,
  isBankCode,
  channel,
  method,
}) => {
  if (hideIcon) {
    return null;
  }

  const defaultIcon = isBankCode ? bankTransferIcon : ussdIcon;
  const isDefaultChannelIcon =
    channel && !PAYMENT_METHODS_ICON[channel?.ui_code] && method;

  const selectedMethodIcon = selectedMethod
    ? PAYMENT_METHODS_ICON[selectedMethod?.toLowerCase().replace(/\s+/g, "_")]
    : defaultIcon;

  let icon = defaultIcon;

  if (selectedMethodIcon) {
    if (typeof selectedMethodIcon === "object") {
      icon =
        resize && selectedMethodIcon.big
          ? selectedMethodIcon.big
          : selectedMethodIcon.small;
    } else {
      icon =
        typeof selectedMethodIcon === "function"
          ? selectedMethodIcon()
          : selectedMethodIcon;
    }
  }

  if (isDefaultChannelIcon) {
    const methodIcon = PAYMENT_METHODS_ICON[method];

    return (
      <StyledSvgColoredImage>
        {typeof methodIcon === "function" ? (
          methodIcon()
        ) : (
          <CurrencyImage src={methodIcon} alt={selectedMethod} />
        )}
      </StyledSvgColoredImage>
    );
  }

  return (
    <StyledPaymentIconWrapper
      data-transaction-name="method-logo"
      resize={resize}
    >
      {coloredIcon && typeof selectedMethodIcon === "function" ? (
        <StyledSvgColoredImage>{icon}</StyledSvgColoredImage>
      ) : (
        <CurrencyImage resize={resize} src={icon} alt={selectedMethod} />
      )}
    </StyledPaymentIconWrapper>
  );
};

export default PaymentMethodsLogo;
