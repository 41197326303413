import certusImg from "../assets/icons/certusImg.png";
import msbImg from "../assets/icons/MSB.png";
import cardImg from "../assets/icons/cardImg.svg";
import korapayImg from "../assets/icons/korapayIcon.svg";
import fincraImg from "../assets/icons/fincraImg.svg";
import wemaBankMainImg from "../assets/icons/wemaBankMainIcon.svg";
import providusBankMainImg from "../assets/icons/providusBankMainIcon.png";
import globusBankMainImg from "../assets/icons/globusBankMainIcon.png";
import wemaBankSmallImg from "../assets/icons/wemaBankSmallIcon.svg";
import providusBankSmallImg from "../assets/icons/providusBankSmallIcon.png";
import globusBankSmallImg from "../assets/icons/globusBankSmallIcon.svg";
import airtelMainIcon from "../assets/icons/airtelMainIcon.svg";
import airtelSmallIcon from "../assets/icons/airtelSmallIcon.svg";
import equitelMainIcon from "../assets/icons/equitelMainIcon.png";
import equitelSmallIcon from "../assets/icons/equitelSmallIcon.png";
import mpesaMainIcon from "../assets/icons/mpesaMainIcon.svg";
import applePayIcon from "../assets/icons/applePayBlackIcon.svg";
import googlePayIcon from "../assets/icons/googlePay.svg";
import mpesalSmallIcon from "../assets/icons/mpesaSmallIcon.svg";
import mtnLogo from "../assets/icons/mtnLogo.png";
import tigoLogo from "../assets/icons/tigoLogo.png";
import moneytoliaIcon from "../assets/icons/moneytoliaIcon.png";
import mtnMainIcon from "../assets/icons/mtnMainIcon.svg";
import vodafoneMainIcon from "../assets/icons/vodafoneMainIcon.png";
import vodafoneSmallIcon from "../assets/icons/vodafoneSmallIcon.svg";
import { getCurrencyIcon } from "./CryptoCurrencyIcon";
import CryptoIcon from "../assets/icons/CryptoIcon";
import CardIcon from "../assets/icons/cardIcon";
import BankTransferIcon from "../assets/icons/bankTransferIcon";
import popyparaIcon from "../assets/icons/popypara.png";
import parazulaIcon from "../assets/icons/parazula.svg";
import pepIcon from "../assets/icons/pepIcon.png";
import paparaIcon from "../assets/icons/paparaIcon.png";
import parolaparaIcon from "../assets/icons/parolapara.png";
import UssdIcon from "../assets/icons/ussdIcon";
import payfixIcon from "../assets/icons/payfix.png";
import mefetsIcon from "../assets/icons/mefets.png";
import PayzIcon from "../assets/icons/logoPayz.png";
import MobileMoneyIcon from "../assets/icons/mobileMoneyIcon";
import TPagaIcon from "../assets/icons/tpagaIcon";
import CheckIconPng from "../assets/icons/checkIcon.png";
import MatchIcon from "../assets/icons/matchIcon.png";
import ToditoIcon from "../assets/icons/iconTodito.png";
import KhipuIcon from "../assets/icons/khipuIcon.png";
import IconCash from "../assets/icons/defaultCashIcon";
import InteracIcon from "../assets/icons/interacImg.png";
import VegaWalletIcon from "../assets/icons/vegaWalletIcon.png";
import OpayWalletIcon from "../assets/icons/opayWallet.png";
import WalletMachbetter from "../assets/icons/muchbetterLogo.png";
import ActivIcon from "../assets/icons/activ.png";
import kcellIcon from "../assets/icons/kcell.png";
import tele2Icon from "../assets/icons/tele2.png";
import beelineIcon from "../assets/icons/beeline.png";
import PalmPayIcon from "../assets/icons/PalmPayIcon.svg";
import blikIcon from "../assets/icons/blikIcon.png";
import { BANK_ICONS } from "./bankIcons";
import qrCodeIcon from "../assets/icons/qrCodeIcon.svg";
import WalletM10Icon from "../assets/icons/methods/walletM10Icon";

export const PAYMENT_METHODS = {
  PIX: "pix",
  BOLETO: "boleto",
  LOTTERY: "lottery",
  UPI: "upi",
  P2P: "p2p",
  CARD: "card",
  P2C: "p2c",
  OPEN_BANKING: "open_banking",
  POPYPARA: "popypara",
  PAROLAPARA: "parolapara",
  PARAZULA: "parazula",
  PEP: "pep",
  PAPARA: "papara",
  MEFETE: "mefete",
  PAYFIX: "payfix",
  PALMPAY_CODE: "pay_code_palmpay",
  CARD_REDIRECT: "card_redirect",
  WALLET_TPAGA: "wallet_tpaga",
  WALLET_CHEK: "wallet_chek",
  WALLET_MACH: "wallet_mach",
  WALLET_TODITO: "wallet_todito",
  WALLET_PALMPAY: "wallet_palmpay",
  BANK_TRANSFER_KHIPU: "bank_transfer_khipu",
  BANK_TRANSFER: "bank_transfer",
  INTERAC_ETRANSFER: "interac_etransfer",
  BANK_TRANSFER_CHILE: "bank_transfer_chile",
  BANK_TRANSFER_ARGENTINA: "bank_transfer_argentina",
  BANK_TRANSFER_JAPAN: "bank_transfer_japan",
  BANK_TRANSFER_SOUTH_KOREA: "bank_transfer_south_korea",
  BANK_TRANSFER_TURKEY: "bank_transfer_turkey",
  BANK_TRANSFER_PERU: "bank_transfer_peru",
  BLIK: "blik",
  CASH_CHILE: "cash_chile",
  APPLE_PAY_REDIRECT: "apple_pay_redirect",
  GOOGLE_PAY_REDIRECT: "google_pay_redirect",
  APPLE_PAY: "apple_pay",
  GOOGLE_PAY: "google_pay",
  CRYPTO: "crypto",
  VIRTUAL_ACCOUNT: "virtual_account",
  MONEYTOLIA: "moneytolia",
  MOBILE_MONEY: "mobile_money",
  BANK_TRANSFER_AFRICA: "bank_transfer_africa",
  BANK_ACCOUNT_AFRICA: "bank_account_africa",
  BANK_ACCOUNT_INDONESIA: "bank_account_indonesia",
  WALLET_PAYZ: "wallet_payz",
  WALLET_VEGA: "wallet_vega",
  WALLET_OPAY: "wallet_opay",
  WALLET_OPAY_QR: "wallet_opay_qr",
  WALLET_MUCHBETTER: "wallet_muchbetter",
  DEPOSIT_OPAY: "deposit_opay",
  DEPOSIT_PALMPAY: "deposit_palmpay",
  USSD: "ussd",
  ZEN_FINGERPRINT: "zen_fingerprint",
  BANK_CODE: "bank_code",
  WALLET_M10: "wallet_m10",
  QR_CODE: "qr_code",
};

export const DISPLAY_PAYMENT_METHODS = {
  card_redirect: "Card Redirect",
  crypto: "Crypto",
  virtual_account: "Bank account",
  apple_pay_redirect: "Apple pay redirect",
  google_pay_redirect: "Google pay redirect",
  apple_pay: "Apple pay",
  google_pay: "Google pay",
  p2c: "P2C",
  p2p: "P2P",
  open_banking: "Pay by bank",
  popypara: "Popypara",
  parolapara: "ParolaPara",
  parazula: "Parazula",
  pep: "Pep",
  papara: "Papara",
  pay_code_palmpay: "PalmPay Code",
  mefete: "Mefete",
  payfix: "Payfix",
  card: "Card",
  boleto: "Boleto",
  lottery: "Lottery",
  pix: "PIX",
  upi: "UPI",
  mobile_money: "Mobile money",
  moneytolia: "Moneytolia",
  bank_transfer_africa: "Bank transfer",
  bank_account_africa: "Bank account",
  bank_account_indonesia: "Bank account",
  blik: "BLIK",
  wallet_tpaga: "TPaga",
  wallet_chek: "Chek",
  wallet_mach: "Mach",
  wallet_todito: "Todito",
  wallet_palmpay: "Wallet PalmPay",
  cash_chile: "Cash",
  bank_transfer: "Bank transfer",
  bank_transfer_argentina: "Bank transfer",
  bank_transfer_turkey: "Bank transfer",
  bank_transfer_khipu: "Khipu",
  bank_transfer_chile: "Bank transfer",
  bank_transfer_japan: "Bank transfer",
  bank_transfer_south_korea: "Bank transfer",
  bank_transfer_peru: "Bank transfer",
  interac_etransfer: "Interac e-Transfer",
  wallet_payz: "Wallet Payz",
  wallet_vega: "VegaWallet",
  wallet_opay: "Opay",
  wallet_muchbetter: "Wallet Muchbetter",
  wallet_opay_qr: "Opay Qr",
  deposit_opay: "Betting deposit OPay",
  deposit_palmpay: "Betting deposit PalmPay",
  ussd: "USSD",
  zen_fingerprint: "Zen",
  bank_code: "Pay to card",
  wallet_m10: "M10 wallet",
  qr_code: "QR code",
};

export const PAYMENT_METHOD_PROVIDERS = {
  CERTUS: "certus",
  BAZPAY: "bazpay",
  FINCRA: "fincra",
  KORAPAY: "korapay",
  MSB: "msb",
  PAGSMILE: "pagsmile",
  ISETTLE: "isettle",
  SIMPLEPAY: "simplepay",
  FLUTTERWAVE: "flutterwave",
  PAYMENTO: "paymento",
  INOPS: "inops",
  ZAMUPAY: "zamupay",
  OPAY: "opay",
  TPAY: "tpay",
};

export const AUTO_PROCESSED_PAYMENT_METHODS = [PAYMENT_METHODS.P2P];

export const MANUALLY_PROCESSED_PAYMENT_METHODS = [
  PAYMENT_METHODS.CARD,
  PAYMENT_METHODS.P2C,
  PAYMENT_METHODS.CARD_REDIRECT,
  PAYMENT_METHODS.APPLE_PAY_REDIRECT,
  PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
  PAYMENT_METHODS.VIRTUAL_ACCOUNT,
  PAYMENT_METHODS.USSD,
  PAYMENT_METHODS.BANK_CODE,
  PAYMENT_METHODS.MOBILE_MONEY,
  PAYMENT_METHODS.MONEYTOLIA,
  PAYMENT_METHODS.OPEN_BANKING,
  PAYMENT_METHODS.PIX,
  PAYMENT_METHODS.POPYPARA,
  PAYMENT_METHODS.PAROLAPARA,
  PAYMENT_METHODS.PAPARA,
  PAYMENT_METHODS.PARAZULA,
  PAYMENT_METHODS.PEP,
  PAYMENT_METHODS.MEFETE,
  PAYMENT_METHODS.PAYFIX,
  PAYMENT_METHODS.PALMPAY_CODE,
  PAYMENT_METHODS.BOLETO,
  PAYMENT_METHODS.LOTTERY,
  PAYMENT_METHODS.UPI,
  PAYMENT_METHODS.BANK_TRANSFER,
  PAYMENT_METHODS.BANK_TRANSFER_AFRICA,
  PAYMENT_METHODS.BANK_ACCOUNT_AFRICA,
  PAYMENT_METHODS.BANK_ACCOUNT_INDONESIA,
  PAYMENT_METHODS.BLIK,
  PAYMENT_METHODS.DEPOSIT_OPAY,
  PAYMENT_METHODS.DEPOSIT_PALMPAY,
  PAYMENT_METHODS.WALLET_PAYZ,
  PAYMENT_METHODS.WALLET_VEGA,
  PAYMENT_METHODS.WALLET_OPAY_QR,
  PAYMENT_METHODS.WALLET_OPAY,
  PAYMENT_METHODS.WALLET_TPAGA,
  PAYMENT_METHODS.ZEN_FINGERPRINT,
  PAYMENT_METHODS.WALLET_CHEK,
  PAYMENT_METHODS.WALLET_MACH,
  PAYMENT_METHODS.WALLET_TODITO,
  PAYMENT_METHODS.WALLET_PALMPAY,
  PAYMENT_METHODS.WALLET_MUCHBETTER,
  PAYMENT_METHODS.CASH_CHILE,
  PAYMENT_METHODS.BANK_TRANSFER_KHIPU,
  PAYMENT_METHODS.BANK_TRANSFER_CHILE,
  PAYMENT_METHODS.BANK_TRANSFER_PERU,
  PAYMENT_METHODS.BANK_TRANSFER_ARGENTINA,
  PAYMENT_METHODS.BANK_TRANSFER_JAPAN,
  PAYMENT_METHODS.BANK_TRANSFER_SOUTH_KOREA,
  PAYMENT_METHODS.BANK_TRANSFER_TURKEY,
  PAYMENT_METHODS.INTERAC_ETRANSFER,
  PAYMENT_METHODS.WALLET_M10,
  PAYMENT_METHODS.QR_CODE,
];

export const EXTERNAL_PROCESSED_PAYMENT_METHODS = [
  PAYMENT_METHODS.APPLE_PAY,
  PAYMENT_METHODS.GOOGLE_PAY,
];

export const METHODS_WITH_TRANSACTION_ON_SELECT = [
  {
    name: PAYMENT_METHODS.VIRTUAL_ACCOUNT,
    dataArrayName: "virtual_account",
    hasFormToSubmit: true,
    getSelected: ({ requisites, selectedMethod }) => {
      if (!requisites) return null;

      const validRequisite = requisites.find(
        (requisite) =>
          !requisite.error_code &&
          requisite.payment_method_id === selectedMethod?.id,
      );

      return (
        validRequisite ||
        requisites.find(
          (requisite) => requisite.payment_method_id === selectedMethod?.id,
        )
      );
    },
    hasProlong: true,
  },
  {
    name: PAYMENT_METHODS.CRYPTO,
    dataArrayName: "crypto",
    hasFormToSubmit: false,
    getSelected: ({ requisites, selectedMethod }) => {
      if (!requisites) return null;

      const validRequisite = requisites.find(
        (requisite) =>
          !requisite.error_code &&
          requisite.currency === selectedMethod?.currency,
      );

      return (
        validRequisite ||
        requisites.find(
          (requisite) => requisite.currency === selectedMethod?.currency,
        )
      );
    },
    hasProlong: true,
  },
  {
    name: PAYMENT_METHODS.DEPOSIT_OPAY,
    dataArrayName: "requisites",
    hasFormToSubmit: true,
    getSelected: ({ requisites, selectedMethod }) => {
      if (!requisites) return null;

      const validRequisite = requisites.find(
        (requisite) =>
          !requisite.error_code &&
          requisite.payment_method_id === selectedMethod?.id,
      );

      return (
        validRequisite ||
        requisites.find(
          (requisite) => requisite.payment_method_id === selectedMethod?.id,
        )
      );
    },
  },
  {
    name: PAYMENT_METHODS.DEPOSIT_PALMPAY,
    dataArrayName: "requisites",
    hasFormToSubmit: true,
    getSelected: ({ requisites, selectedMethod }) => {
      if (!requisites) return null;

      const validRequisite = requisites.find(
        (requisite) =>
          !requisite.error_code &&
          requisite.payment_method_id === selectedMethod?.id,
      );

      return (
        validRequisite ||
        requisites.find(
          (requisite) => requisite.payment_method_id === selectedMethod?.id,
        )
      );
    },
  },
];

// Methods are initially manually processed
// But after processing they act like non-processed
export const PRE_PROCESSED_PAYMENT_METHODS =
  METHODS_WITH_TRANSACTION_ON_SELECT.reduce((acc, item) => {
    if (item.hasFormToSubmit) {
      acc.push(item.name);
    }

    return acc;
  }, []);

export const NON_PROCESSED_PAYMENT_METHODS =
  METHODS_WITH_TRANSACTION_ON_SELECT.reduce((acc, item) => {
    if (!item.hasFormToSubmit) {
      acc.push(item.name);
    }

    return acc;
  }, []);

export const SUPPORTED_PAYMENT_METHODS = [
  ...AUTO_PROCESSED_PAYMENT_METHODS,
  ...MANUALLY_PROCESSED_PAYMENT_METHODS,
  ...NON_PROCESSED_PAYMENT_METHODS,
];

const PAYMENT_METHODS_COMPONENT_ICONS = {
  card: () => <CardIcon />,
  crypto: () => <CryptoIcon />,
  virtual_account: () => <BankTransferIcon />,
  bank_code: () => <BankTransferIcon />,
  ussd: () => <UssdIcon />,
  upi: () => <UssdIcon />,
  p2c: () => <UssdIcon />,
  p2p: () => <UssdIcon />,
  card_redirect: () => <UssdIcon />,
  pix: () => <UssdIcon />,
  lottery: () => <UssdIcon />,
  boleto: () => <UssdIcon />,
  bank_transfer: () => <BankTransferIcon />,
  open_banking: () => <BankTransferIcon />,
  bank_transfer_argentina: () => <BankTransferIcon />,
  bank_transfer_japan: () => <BankTransferIcon />,
  bank_transfer_south_korea: () => <BankTransferIcon />,
  bank_transfer_turkey: () => <BankTransferIcon />,
  bank_transfer_africa: () => <BankTransferIcon />,
  bank_account_africa: () => <BankTransferIcon />,
  bank_account_indonesia: () => <BankTransferIcon />,
  bank_transfer_chile: () => <BankTransferIcon />,
  bank_transfer_peru: () => <BankTransferIcon />,
  zen_fingerprint: () => <BankTransferIcon />,
  mobile_money: () => <MobileMoneyIcon />,
  wallet_tpaga: () => <TPagaIcon />,
  cash_chile: () => <IconCash />,
  wallet_payz: PayzIcon,
  wallet_vega: VegaWalletIcon,
  wallet_opay: OpayWalletIcon,
  wallet_opay_qr: OpayWalletIcon,
  deposit_opay: OpayWalletIcon,
  deposit_palmpay: PalmPayIcon,
  wallet_palmpay: PalmPayIcon,
  wallet_chek: CheckIconPng,
  wallet_mach: MatchIcon,
  wallet_todito: ToditoIcon,
  wallet_muchbetter: WalletMachbetter,
  bank_transfer_khipu: KhipuIcon,
  interac_etransfer: InteracIcon,
  activ: ActivIcon,
  kcell: kcellIcon,
  tele2: tele2Icon,
  beeline: beelineIcon,
  wallet_m10: () => <WalletM10Icon />,
};

const PAYMENT_PROVIDER_ICONS = {
  wema_bank: {
    big: wemaBankMainImg,
    small: wemaBankSmallImg,
  },
  wema: {
    big: wemaBankMainImg,
    small: wemaBankSmallImg,
  },
  globus_bank: {
    big: globusBankMainImg,
    small: globusBankSmallImg,
  },
  providus_bank: {
    big: providusBankMainImg,
    small: providusBankSmallImg,
  },
  mpesa: {
    big: mpesaMainIcon,
    small: mpesalSmallIcon,
  },
  vodafone: {
    big: vodafoneMainIcon,
    small: vodafoneSmallIcon,
  },
  mtn: {
    big: mtnMainIcon,
    small: mtnLogo,
  },
  airtel: {
    big: airtelMainIcon,
    small: airtelSmallIcon,
  },
  equitel: {
    big: equitelMainIcon,
    small: equitelSmallIcon,
  },
};

export const DISPLAY_PAYMENT_PROVIDERS = {
  msb: "MSB Finance",
  pagsmile: "WebPay ( Debit/Credit Card )",
};

export const PAYMENT_METHOD_SUBTITLE = {
  [PAYMENT_METHODS.MOBILE_MONEY]: "choose_mobile_operator",
};

export const PAYMENT_METHODS_ICON = {
  certus: certusImg,
  apple_pay_redirect: applePayIcon,
  google_pay_redirect: googlePayIcon,
  korapay: korapayImg,
  fincra: fincraImg,
  msb: msbImg,
  pagsmile: cardImg,
  popypara: popyparaIcon,
  parolapara: parolaparaIcon,
  parazula: parazulaIcon,
  pep: pepIcon,
  papara: paparaIcon,
  payfix: payfixIcon,
  pay_code_palmpay: PalmPayIcon,
  qr_code: qrCodeIcon,
  mefete: mefetsIcon,
  tigo: tigoLogo,
  blik: blikIcon,
  moneytolia: moneytoliaIcon,
  ...getCurrencyIcon,
  ...PAYMENT_METHODS_COMPONENT_ICONS,
  ...PAYMENT_PROVIDER_ICONS,
  ...BANK_ICONS,
};
