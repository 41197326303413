import { useEffect } from "preact/hooks";
import { apm } from "@elastic/apm-rum";

const useApmPageTracking = (name: string, type = "page") => {
  useEffect(() => {
    const transaction = apm.startTransaction(name, type);

    return () => {
      if (transaction) {
        transaction.end();
      }
    };
  }, [name, type]);
};

export default useApmPageTracking;
