import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const NotificationIcon = styled.img`
  margin-right: 10px;
`;

export const NotificationText = styled.span`
  span {
    color: ${({ theme }) => theme?.notificationTextColor};
    font-weight: 600;
    font-size: 14px;
  }
`;

export const NotificationButton = styled.button`
  outline: none;
  height: 24px;
  padding: 0 12px;
  border-radius: 15px;
  border: 1px solid ${COLORS.WHITE};
  background: transparent;
  margin: 0 auto 0 0;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 700;
    color: ${COLORS.WHITE};
  }
`;

export const NotificationContainer = styled.div`
  position: fixed;
  min-width: 320px;
  width: ${({ theme }) => theme?.notification?.width};
  padding: 16px;
  background-color: ${(props) => {
    switch (props.$status) {
      case "success":
        return props.theme.notificationColor;
      case "fail":
        return props.theme.notificationColorError;
      case "error":
        return COLORS.DANGER;
      default:
        props.theme.notificationColor;
    }
  }};
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  align-items: start;
  flex-wrap: nowrap;
  flex-direction: row;
  left: 50%;
  top: ${({ theme }) => theme?.notification?.top};
  transform: translateX(-50%);
  z-index: 1001;
  gap: 20px;

  @media (min-width: 768px) and (min-height: 500px) {
    border-top-right-radius: ${({ theme }) =>
      theme?.notification?.borderTopRadius};
    border-top-left-radius: ${({ theme }) =>
      theme?.notification?.borderTopRadius};
    border-bottom-right-radius: ${({ theme }) =>
      theme?.notification?.borderBottomRadius};
    border-bottom-left-radius: ${({ theme }) =>
      theme?.notification?.borderBottomRadius};
  }
`;

export const NotificationBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: -4px;
`;

export const NotificationCloseIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
