import { i18n, logger } from "../../services";
import { PopupActionTitle, PopupItemLoadingBlock } from "../../styles";
import Loader from "../Loaders";
import useLocaleManager from "../../hooks/useLocaleManager";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import { PopupContentActionWrapper } from "./style";

interface LanguageModalProps {
  onChange?: (locale: string) => void;
}

const LanguageModal: React.FC<LanguageModalProps> = ({ onChange }) => {
  const { handleCloseModal } = useContext(ContextUI);

  const {
    locale: activeLocale,
    isLoading,
    setLocale,
  } = useLocaleManager(i18n.getLocale());

  const handleLocaleChange = async (newLocale: string) => {
    try {
      await setLocale(newLocale);
      handleCloseModal();

      if (onChange) {
        onChange(newLocale);
      }
    } catch (error) {
      logger.error("Failed to change locale:", error);
    }
  };

  return (
    <>
      {i18n.getLanguageConfig().map(({ locale, title, url }) => (
        <PopupContentActionWrapper
          key={locale}
          $isActive={activeLocale === locale}
          onClick={() => handleLocaleChange(locale)}
        >
          {isLoading && activeLocale === locale ? (
            <PopupItemLoadingBlock>
              <Loader size="small" />
            </PopupItemLoadingBlock>
          ) : (
            <img src={url} alt={`${title} flag`} />
          )}
          <PopupActionTitle>{title}</PopupActionTitle>
        </PopupContentActionWrapper>
      ))}
    </>
  );
};

export default LanguageModal;
