export const COUNTRIES_FULL_LIST = [
  { name: "AF", label: "Afghanistan" },
  { name: "AL", label: "Albania" },
  { name: "DZ", label: "Algeria" },
  { name: "AS", label: "American Samoa" },
  { name: "AD", label: "Andorra" },
  { name: "AO", label: "Angola" },
  { name: "AI", label: "Anguilla" },
  { name: "AQ", label: "Antarctica" },
  { name: "AG", label: "Antigua and Barbuda" },
  { name: "AR", label: "Argentina" },
  { name: "AM", label: "Armenia" },
  { name: "AW", label: "Aruba" },
  { name: "AU", label: "Australia" },
  { name: "AT", label: "Austria" },
  { name: "AZ", label: "Azerbaijan" },
  { name: "BS", label: "Bahamas" },
  { name: "BH", label: "Bahrain" },
  { name: "BD", label: "Bangladesh" },
  { name: "BB", label: "Barbados" },
  { name: "BY", label: "Belarus" },
  { name: "BE", label: "Belgium" },
  { name: "BZ", label: "Belize" },
  { name: "BJ", label: "Benin" },
  { name: "BM", label: "Bermuda" },
  { name: "BT", label: "Bhutan" },
  { name: "BO", label: "Bolivia" },
  { name: "BA", label: "Bosnia and Herzegovina" },
  { name: "BW", label: "Botswana" },
  { name: "BV", label: "Bouvet Island" },
  { name: "BR", label: "Brazil" },
  { name: "IO", label: "British Indian Ocean Territory" },
  { name: "VG", label: "British Virgin Islands" },
  { name: "BN", label: "Brunei" },
  { name: "BG", label: "Bulgaria" },
  { name: "BF", label: "Burkina Faso" },
  { name: "BI", label: "Burundi" },
  { name: "KH", label: "Cambodia" },
  { name: "CM", label: "Cameroon" },
  { name: "CA", label: "Canada" },
  { name: "CV", label: "Cape Verde" },
  { name: "KY", label: "Cayman Islands" },
  { name: "CF", label: "Central African Republic" },
  { name: "TD", label: "Chad" },
  { name: "CL", label: "Chile" },
  { name: "CN", label: "China" },
  { name: "CX", label: "Christmas Island" },
  { name: "CC", label: "Cocos Islands" },
  { name: "CO", label: "Colombia" },
  { name: "KM", label: "Comoros" },
  { name: "CK", label: "Cook Islands" },
  { name: "CR", label: "Costa Rica" },
  { name: "HR", label: "Croatia" },
  { name: "CU", label: "Cuba" },
  { name: "CY", label: "Cyprus" },
  { name: "CZ", label: "Czech Republic" },
  { name: "CD", label: "Democratic Republic of the Congo" },
  { name: "DK", label: "Denmark" },
  { name: "DJ", label: "Djibouti" },
  { name: "DM", label: "Dominica" },
  { name: "DO", label: "Dominican Republic" },
  { name: "TL", label: "East Timor" },
  { name: "EC", label: "Ecuador" },
  { name: "EG", label: "Egypt" },
  { name: "SV", label: "El Salvador" },
  { name: "GQ", label: "Equatorial Guinea" },
  { name: "ER", label: "Eritrea" },
  { name: "EE", label: "Estonia" },
  { name: "ET", label: "Ethiopia" },
  { name: "FK", label: "Falkland Islands" },
  { name: "FO", label: "Faroe Islands" },
  { name: "FJ", label: "Fiji" },
  { name: "FI", label: "Finland" },
  { name: "FR", label: "France" },
  { name: "GF", label: "French Guiana" },
  { name: "PF", label: "French Polynesia" },
  { name: "TF", label: "French Southern Territories" },
  { name: "GA", label: "Gabon" },
  { name: "GM", label: "Gambia" },
  { name: "GE", label: "Georgia" },
  { name: "DE", label: "Germany" },
  { name: "GH", label: "Ghana" },
  { name: "GI", label: "Gibraltar" },
  { name: "GR", label: "Greece" },
  { name: "GL", label: "Greenland" },
  { name: "GD", label: "Grenada" },
  { name: "GP", label: "Guadeloupe" },
  { name: "GU", label: "Guam" },
  { name: "GT", label: "Guatemala" },
  { name: "GN", label: "Guinea" },
  { name: "GW", label: "Guinea-Bissau" },
  { name: "GY", label: "Guyana" },
  { name: "HT", label: "Haiti" },
  { name: "HM", label: "Heard Island and McDonald Islands" },
  { name: "HN", label: "Honduras" },
  { name: "HK", label: "Hong Kong" },
  { name: "HU", label: "Hungary" },
  { name: "IS", label: "Iceland" },
  { name: "IN", label: "India" },
  { name: "ID", label: "Indonesia" },
  { name: "IR", label: "Iran" },
  { name: "IQ", label: "Iraq" },
  { name: "IE", label: "Ireland" },
  { name: "IL", label: "Israel" },
  { name: "IT", label: "Italy" },
  { name: "CI", label: "Ivory Coast" },
  { name: "JM", label: "Jamaica" },
  { name: "JP", label: "Japan" },
  { name: "JO", label: "Jordan" },
  { name: "KZ", label: "Kazakhstan" },
  { name: "KE", label: "Kenya" },
  { name: "KI", label: "Kiribati" },
  { name: "XK", label: "Kosovo" },
  { name: "KW", label: "Kuwait" },
  { name: "KG", label: "Kyrgyzstan" },
  { name: "LA", label: "Laos" },
  { name: "LV", label: "Latvia" },
  { name: "LB", label: "Lebanon" },
  { name: "LS", label: "Lesotho" },
  { name: "LR", label: "Liberia" },
  { name: "LY", label: "Libya" },
  { name: "LI", label: "Liechtenstein" },
  { name: "LT", label: "Lithuania" },
  { name: "LU", label: "Luxembourg" },
  { name: "MO", label: "Macao" },
  { name: "MK", label: "Macedonia" },
  { name: "MG", label: "Madagascar" },
  { name: "MW", label: "Malawi" },
  { name: "MY", label: "Malaysia" },
  { name: "MV", label: "Maldives" },
  { name: "ML", label: "Mali" },
  { name: "MT", label: "Malta" },
  { name: "MH", label: "Marshall Islands" },
  { name: "MQ", label: "Martinique" },
  { name: "MR", label: "Mauritania" },
  { name: "MU", label: "Mauritius" },
  { name: "YT", label: "Mayotte" },
  { name: "MX", label: "Mexico" },
  { name: "FM", label: "Micronesia" },
  { name: "MD", label: "Moldova" },
  { name: "MC", label: "Monaco" },
  { name: "MN", label: "Mongolia" },
  { name: "MS", label: "Montserrat" },
  { name: "MA", label: "Morocco" },
  { name: "MZ", label: "Mozambique" },
  { name: "MM", label: "Myanmar" },
  { name: "NA", label: "Namibia" },
  { name: "NR", label: "Nauru" },
  { name: "NP", label: "Nepal" },
  { name: "NL", label: "Netherlands" },
  { name: "AN", label: "Netherlands Antilles" },
  { name: "NC", label: "New Caledonia" },
  { name: "NZ", label: "New Zealand" },
  { name: "NI", label: "Nicaragua" },
  { name: "NE", label: "Niger" },
  { name: "NG", label: "Nigeria" },
  { name: "NU", label: "Niue" },
  { name: "NF", label: "Norfolk Island" },
  { name: "KP", label: "North Korea" },
  { name: "MP", label: "Northern Mariana Islands" },
  { name: "NO", label: "Norway" },
  { name: "OM", label: "Oman" },
  { name: "PK", label: "Pakistan" },
  { name: "PW", label: "Palau" },
  { name: "PS", label: "Palestinian Territory" },
  { name: "PA", label: "Panama" },
  { name: "PG", label: "Papua New Guinea" },
  { name: "PY", label: "Paraguay" },
  { name: "PE", label: "Peru" },
  { name: "PH", label: "Philippines" },
  { name: "PN", label: "Pitcairn" },
  { name: "PL", label: "Poland" },
  { name: "PT", label: "Portugal" },
  { name: "PR", label: "Puerto Rico" },
  { name: "QA", label: "Qatar" },
  { name: "CG", label: "Republic of the Congo" },
  { name: "RE", label: "Reunion" },
  { name: "RO", label: "Romania" },
  { name: "RU", label: "Russia" },
  { name: "RW", label: "Rwanda" },
  { name: "SH", label: "Saint Helena" },
  { name: "KN", label: "Saint Kitts and Nevis" },
  { name: "LC", label: "Saint Lucia" },
  { name: "PM", label: "Saint Pierre and Miquelon" },
  { name: "VC", label: "Saint Vincent and the Grenadines" },
  { name: "WS", label: "Samoa" },
  { name: "SM", label: "San Marino" },
  { name: "ST", label: "Sao Tome and Principe" },
  { name: "SA", label: "Saudi Arabia" },
  { name: "SN", label: "Senegal" },
  { name: "SC", label: "Seychelles" },
  { name: "SL", label: "Sierra Leone" },
  { name: "SG", label: "Singapore" },
  { name: "SK", label: "Slovakia" },
  { name: "SI", label: "Slovenia" },
  { name: "SB", label: "Solomon Islands" },
  { name: "SO", label: "Somalia" },
  { name: "ZA", label: "South Africa" },
  { name: "GS", label: "South Georgia and the South Sandwich Islands" },
  { name: "KR", label: "South Korea" },
  { name: "ES", label: "Spain" },
  { name: "LK", label: "Sri Lanka" },
  { name: "SD", label: "Sudan" },
  { name: "SR", label: "Suriname" },
  { name: "SJ", label: "Svalbard and Jan Mayen" },
  { name: "SZ", label: "Swaziland" },
  { name: "SE", label: "Sweden" },
  { name: "CH", label: "Switzerland" },
  { name: "SY", label: "Syria" },
  { name: "TW", label: "Taiwan" },
  { name: "TJ", label: "Tajikistan" },
  { name: "TZ", label: "Tanzania" },
  { name: "TH", label: "Thailand" },
  { name: "TG", label: "Togo" },
  { name: "TK", label: "Tokelau" },
  { name: "TO", label: "Tonga" },
  { name: "TT", label: "Trinidad and Tobago" },
  { name: "TN", label: "Tunisia" },
  { name: "TR", label: "Turkey" },
  { name: "TM", label: "Turkmenistan" },
  { name: "TC", label: "Turks and Caicos Islands" },
  { name: "TV", label: "Tuvalu" },
  { name: "VI", label: "U.S. Virgin Islands" },
  { name: "UG", label: "Uganda" },
  { name: "UA", label: "Ukraine" },
  { name: "AE", label: "United Arab Emirates" },
  { name: "GB", label: "United Kingdom" },
  { name: "US", label: "United States" },
  { name: "UM", label: "United States Minor Outlying Islands" },
  { name: "UY", label: "Uruguay" },
  { name: "UZ", label: "Uzbekistan" },
  { name: "VU", label: "Vanuatu" },
  { name: "VA", label: "Vatican" },
  { name: "VE", label: "Venezuela" },
  { name: "VN", label: "Vietnam" },
  { name: "WF", label: "Wallis and Futuna" },
  { name: "EH", label: "Western Sahara" },
  { name: "YE", label: "Yemen" },
  { name: "ZM", label: "Zambia" },
  { name: "ZW", label: "Zimbabwe" },
  { name: "SS", label: "South Sudan" },
  { name: "AX", label: "Åland Islands" },
  { name: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
  { name: "BL", label: "Saint Barthélemy" },
  { name: "CW", label: "Curaçao" },
  { name: "GG", label: "Guernsey" },
  { name: "IM", label: "Isle of Man" },
  { name: "JE", label: "Jersey" },
  { name: "MF", label: "Saint Martin (French part)" },
  { name: "ME", label: "Montenegro" },
  { name: "RS", label: "Serbia" },
  { name: "SX", label: "Sint Maarten (Dutch part)" },
];
