import { useEffect } from "preact/hooks";
import { api, state } from "../../../services";
import ChallengeForm from "../ChallengeForm";
import Instruction from "../../Instruction";

const BankTransferJapan = ({
  status,
  challenge,
  sessionId,
  signature,
  currentMethod,
}) => {
  const onChallengeFormSubmit = () => {
    state.setLoading({
      status: true,
      eventName: "challengeSubmit",
    });
  };

  useEffect(() => {
    if (status === "challenge_succeeded") {
      onChallengeFormSubmit();
    }
  }, [status]);

  return (
    <>
      <Instruction configName="select_amount_challenge" />
      <ChallengeForm
        challengeType={challenge?.type}
        challengeUrl={`${api.baseApiUrl}/payment/challenge/select-amount/${sessionId}/${signature}`}
        challenge={challenge}
        onFormSubmit={onChallengeFormSubmit}
        onFormSubmitFail={() => {
          state.setLoading({
            status: false,
            eventName: "challengeSubmit",
          });
        }}
        sessionId={sessionId}
        signature={signature}
        currentMethod={currentMethod}
      />
    </>
  );
};

export default BankTransferJapan;
