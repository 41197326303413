import * as Preact from "preact";
import ArrowIcon from "../../assets/icons/ui/arrow";
import { DISPLAY_PAYMENT_METHODS } from "../../constants/paymentMethods";
import { state } from "../../services";
import PaymentMethodsLogo from "../PaymentMethods/PaymentMethodsLogo";
import {
  ChannelTitleWrapper,
  InnerLine,
  PaymentDetailsButton,
  PaymentDetailsHeadTitle,
  PaymentDetailsHeadWrapper,
} from "./style";
import { CHANNEL_BANK_NAME } from "../../constants/channelBankName";

interface PaymentDetailsHeadProps {
  onToggleOpen: (event: Preact.JSX.TargetedMouseEvent<HTMLDivElement>) => void;
  isOpen: boolean;
  hasMoreDetailsButton?: boolean;
  hasUnderline?: boolean;
}

const PaymentDetailsHead = ({
  onToggleOpen,
  isOpen,
  hasMoreDetailsButton = true,
  hasUnderline = true,
}: PaymentDetailsHeadProps) => {
  const selectedMethod = state.getSelectedPaymentMethod();

  const { method, channel } = selectedMethod || {};
  const { ui_code } = channel || {};

  if (!selectedMethod) {
    return null;
  }

  return (
    <>
      <PaymentDetailsHeadWrapper>
        {/* @ts-ignore */}
        <PaymentMethodsLogo coloredIcon selectedMethod={method} />
        <PaymentDetailsHeadTitle data-transaction-name="method-name">
          {method &&
            DISPLAY_PAYMENT_METHODS[
              method as keyof typeof DISPLAY_PAYMENT_METHODS
            ]}
        </PaymentDetailsHeadTitle>
        {hasMoreDetailsButton ? (
          <PaymentDetailsButton
            onClick={onToggleOpen}
            $isOpen={isOpen}
            data-transaction-name="payment-details-button"
          >
            <ArrowIcon />
          </PaymentDetailsButton>
        ) : null}
        {ui_code && !hasMoreDetailsButton ? (
          <ChannelTitleWrapper>
            {/* @ts-ignore */}
            <PaymentMethodsLogo coloredIcon selectedMethod={ui_code} />
            <span>
              {CHANNEL_BANK_NAME[ui_code as keyof typeof CHANNEL_BANK_NAME]}
            </span>
          </ChannelTitleWrapper>
        ) : null}
      </PaymentDetailsHeadWrapper>
      {hasUnderline ? <InnerLine /> : null}
    </>
  );
};

export default PaymentDetailsHead;
