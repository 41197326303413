import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { state } from "../../services";
import { useLogo } from "../../services/hooks";
import { HeaderLogoWrapper, LogoBrandHeader, OuterBlockHeader } from "./style";
import FormHeaderLanguageAction from "../FormHeader/FormHeaderLanguageAction";
import GoBackAction from "../GoBackButton";

interface HeaderProps {
  hasGradient?: boolean;
}

const Header = ({ hasGradient }: HeaderProps) => {
  const { onGoBackButtonClick } = useContext(Context);

  // TODO: need fix after move state.js to state.ts
  // @ts-ignore
  const options = state.get().options;

  const { cancel_url, theme } = options || {};

  const logoIcon = useLogo(theme);

  const canReturnToMethods = state.hasPaymentMethodsToShow();

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper $hasGradient={hasGradient}>
        {cancel_url || canReturnToMethods ? (
          <GoBackAction
            onClick={onGoBackButtonClick}
            cancelUrl={cancel_url}
            cancelText="goBack"
          />
        ) : null}
        {logoIcon ? (
          <LogoBrandHeader data-transaction-name="logo" src={`${logoIcon}`} />
        ) : null}
        <FormHeaderLanguageAction />
      </HeaderLogoWrapper>
    </OuterBlockHeader>
  );
};

export default Header;
