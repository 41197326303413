import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { api, i18n, state } from "../../services";
import {
  METHODS_WITH_TRANSACTION_ON_SELECT,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import VirtualAccount from "./VirtualAccount";
import Crypto from "./Crypto";
import DepositPay from "./DepositPay";
import { ContextUI } from "../../providers/UIProvider";
import { Context } from "../../app";

const PROLONG_TIME = 5 * 60 * 1000;
const PROLONG_TIME_WITH_ERROR = 1 * 60 * 1000;

const NonProcessedMethod = ({ handleCopy }) => {
  const prolongateTimerRef = useRef(null);

  const { setNotificationData } = useContext(ContextUI);
  const { currentState = {} } = useContext(Context);

  const [currentMethod, setCurrentMethod] = useState(null);

  const selectedMethod = state.getSelectedPaymentMethod();

  useEffect(() => {
    const method = METHODS_WITH_TRANSACTION_ON_SELECT.find(
      (data) => selectedMethod?.method === data.name,
    );

    const requisites = currentState[method?.dataArrayName];

    const selected = method?.getSelected({ requisites, selectedMethod });

    if (requisites?.length > 0 && selected) {
      setCurrentMethod({
        ...(method || {}),
        requisites: [...(requisites || [])],
        selected,
      });
    }
  }, [currentState]);

  const prolongateRequest = () => {
    return api
      .prolongate()
      .then((res) => {
        const newTime =
          res.status !== 200 ? PROLONG_TIME_WITH_ERROR : PROLONG_TIME;

        setTimeout(() => {
          prolongateRequest();
        }, newTime);
      })
      .catch((err) => {
        const newTime =
          err?.response?.status !== 200
            ? PROLONG_TIME_WITH_ERROR
            : PROLONG_TIME;

        setTimeout(() => {
          prolongateRequest();
        }, newTime);
      });
  };

  useEffect(() => {
    clearTimeout(prolongateTimerRef?.current);

    if (currentMethod?.hasProlong) {
      prolongateRequest();
    }

    return () => {
      clearTimeout(prolongateTimerRef?.current);
    };
  }, [
    currentMethod?.selected?.payment_method_id,
    currentMethod?.selected?.currency,
  ]);

  const onMethodError = () => {
    state.onPaymentMethodSelected({ id: null });

    setNotificationData((prev) => ({
      ...prev,
      isOpen: true,
      status: "fail",
      text: i18n.getMessage({ message: "tryAnotherMethod" }),
    }));
  };

  useEffect(() => {
    if (currentMethod?.selected?.error_code && selectedMethod?.id) {
      onMethodError();
    }
  }, [currentMethod?.selected?.error_code, selectedMethod?.id]);

  switch (selectedMethod?.method) {
    case PAYMENT_METHODS.CRYPTO:
      return <Crypto />;
    case PAYMENT_METHODS.VIRTUAL_ACCOUNT:
      return (
        <VirtualAccount
          selectedVirtualAccount={currentMethod?.selected}
          handleCopy={handleCopy}
        />
      );
    case PAYMENT_METHODS.DEPOSIT_OPAY:
    case PAYMENT_METHODS.DEPOSIT_PALMPAY:
      return (
        <DepositPay
          data={currentMethod?.selected?.[selectedMethod?.method]}
          handleCopy={handleCopy}
        />
      );
    default:
      return null;
  }
};

export default NonProcessedMethod;
