import { useRef, useContext } from "preact/hooks";
import Trans from "../Trans";
import IconCopy from "../../assets/icons/iconCopy";
import {
  SuccessPageBlockWrapper,
  SuccessPageWrapper,
  TransactionInfoFirstBlock,
  PaymentTitle,
  PaymentInfoBlock,
  TransactionTitle,
  TransactionInfoSecondBlock,
  TransactionInfoErrorBlock,
  TransactionDecimalTitle,
  TransactionSumTitle,
  ErrorTitle,
  TransactionIDTitle,
  TransactionRefTitle,
  BaseButton,
  IconColorWrapper,
  LogoBrandHeader,
} from "../../styles";
import colorSchema from "../../colorThemes/colorSchemes";
import brand from "../../services/brand";
import { ContextUI } from "../../providers/UIProvider";

const FailPageDefault = ({
  currency,
  amount,
  reference,
  onButtonClick,
  title,
  description,
  hasGoBack = false,
}) => {
  const parts = amount?.toString()?.split(".");
  const wholeNumber = parts && parts[0];
  const decimal = parts && parts[1];

  const brandData = brand.getBrand();
  const errorIcon = colorSchema?.[brandData?.color_scheme]?.errorIcon;

  const transactionIdRef = useRef(null);

  const { setNotificationData } = useContext(ContextUI);

  const handleCopy = (value, title) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setNotificationData({ text: title, status: "success", isOpen: true });
  };

  return (
    <SuccessPageBlockWrapper>
      <SuccessPageWrapper>
        <TransactionInfoFirstBlock>
          <div style={{ position: "relative" }}>
            {errorIcon && (
              <LogoBrandHeader
                style={{
                  width: "64px",
                  height: "64px",
                }}
                src={`${errorIcon}`}
              />
            )}
          </div>

          <PaymentTitle>
            <Trans message={title || "transactionFailed"} />
          </PaymentTitle>
        </TransactionInfoFirstBlock>
        <TransactionInfoErrorBlock>
          <ErrorTitle>
            <Trans message={description || "errorTtl"} />
          </ErrorTitle>
        </TransactionInfoErrorBlock>

        <TransactionInfoSecondBlock>
          {wholeNumber && (
            <PaymentInfoBlock>
              <TransactionTitle>
                <Trans message="TotalDue" />
              </TransactionTitle>
              <span>
                <TransactionSumTitle color="#eb0075">
                  {wholeNumber}
                </TransactionSumTitle>
                {decimal && (
                  <TransactionDecimalTitle color="#eb0075">
                    .{decimal}
                  </TransactionDecimalTitle>
                )}
                <TransactionDecimalTitle
                  style={{ marginLeft: "8px" }}
                  color="#eb0075"
                >
                  {currency}{" "}
                </TransactionDecimalTitle>
              </span>
            </PaymentInfoBlock>
          )}
          {reference && (
            <PaymentInfoBlock>
              <TransactionIDTitle>
                <Trans message="transactionIdTitle" />
              </TransactionIDTitle>
              <span style={{ display: "flex", alignItems: "flex-start" }}>
                <TransactionRefTitle ref={transactionIdRef}>
                  {reference}
                </TransactionRefTitle>{" "}
                <span
                  onClick={() => handleCopy(reference, "transactionIdCopy")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconColorWrapper>
                    <IconCopy />
                  </IconColorWrapper>
                </span>
              </span>
            </PaymentInfoBlock>
          )}

          {hasGoBack && (
            <BaseButton
              data-role="back_to_store_button"
              style={{ marginTop: "auto" }}
              onClick={onButtonClick}
            >
              <Trans message="goBack" />
            </BaseButton>
          )}
        </TransactionInfoSecondBlock>
      </SuccessPageWrapper>
    </SuccessPageBlockWrapper>
  );
};

export default FailPageDefault;
