import { useEffect, useContext, useRef } from "preact/hooks";
import notificationError from "../../assets/icons/notificationError.svg";
import notificationSuccess from "../../assets/icons/notificationSuccess.svg";
import notificationSuccessKombine from "../../assets/icons/kombineSuccessIcon.svg";
import closeIcon from "../../assets/icons/close.svg";
import Trans from "../Trans";
import { ContextUI } from "../../providers/UIProvider";
import { useData } from "../../services/hooks";
import { state } from "../../services";
import {
  NotificationIcon,
  NotificationText,
  NotificationContainer,
  NotificationCloseIcon,
  NotificationButton,
  NotificationBodyContainer,
} from "./style";

const SuccessIconConfig = {
  default: notificationSuccess,
  kombine: notificationSuccessKombine,
};

const Notification = () => {
  const { notificationData, setNotificationData, handleCloseNotification } =
    useContext(ContextUI);

  const currentState = useData(state.get());
  const theme = currentState?.options?.theme || "default";

  const {
    isOpen,
    status,
    text,
    isCloseAutomatically = true,
    buttonConfig,
  } = notificationData;

  const { hasButton, buttonText, buttonAction } = buttonConfig || {};

  const timerRef = useRef(null);
  const previousTextRef = useRef(text);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (isCloseAutomatically && previousTextRef.current !== text) {
      previousTextRef.current = text;
      timerRef.current = setTimeout(() => {
        handleCloseNotification();
      }, 3000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isOpen, isCloseAutomatically, text]);

  useEffect(() => {
    if (currentState?.notification) {
      const { isError, title, status, isCloseAutomatically } =
        currentState?.notification || {};
      const tempData = { ...notificationData };

      setNotificationData((prev) => ({
        ...prev,
        isOpen: true,
        status: isError ? "error" : status,
        text: title,
      }));

      if (isCloseAutomatically === undefined) {
        setTimeout(() => {
          setNotificationData(tempData);
        }, 3000);
      }
    }
  }, [currentState?.notification]);

  const handleClick = () => {
    buttonAction();
  };

  return (
    <NotificationContainer
      $status={status}
      $isOpen={isOpen}
      data-transaction-name="notification"
    >
      <NotificationIcon
        src={
          status === "success"
            ? SuccessIconConfig[theme] || SuccessIconConfig["default"]
            : notificationError
        }
        alt="Notification Icon"
      />
      <NotificationBodyContainer data-transaction-name="notification-content">
        <NotificationText>
          <Trans message={text} />
        </NotificationText>
        {hasButton && buttonText && buttonAction ? (
          <NotificationButton onClick={() => handleClick()}>
            <Trans message={buttonText} />
          </NotificationButton>
        ) : null}
      </NotificationBodyContainer>
      <NotificationCloseIcon onClick={handleCloseNotification}>
        <img src={closeIcon} alt="Notification close icon" />
      </NotificationCloseIcon>
    </NotificationContainer>
  );
};

export default Notification;
