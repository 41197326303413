import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { api, crypto } from "../../services";
import CryptoHeaderForm from "./CryptoHeaderForm";
import AsyncComponent from "../AsyncComponent";

const CryptoWidget = ({ currentState, handleCopy, onChangeLanguage }) => {
  const { matchesData, setCurrentCryptoState } = useContext(Context);
  const { type, signature, sessionId } = matchesData?.matches || {};

  const onAmountChange = (updatedData) => {
    crypto.set(updatedData);

    return api
      .getInitDataCrypto({
        type,
        sessionId,
        signature,
        updatedData,
      })
      .then((res) => {
        setCurrentCryptoState((state) => ({
          ...state,
          ...(res?.data || res || {}),
        }));

        return res;
      });
  };

  return (
    <>
      <CryptoHeaderForm onChangeLanguage={onChangeLanguage} />
      <AsyncComponent
        importFn={(timestamp) => import(`./CryptoContent.js${timestamp}`)}
        name="CryptoContent"
      >
        {(CryptoContent) => (
          <CryptoContent
            isCryptoFormVisible={currentState?.exchange_allow_calculator}
            onAmountChange={onAmountChange}
            currentState={currentState}
            handleCopy={handleCopy}
          />
        )}
      </AsyncComponent>
    </>
  );
};

export default CryptoWidget;
