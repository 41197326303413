import { useState, useEffect } from "preact/hooks";
import {
  TimeRemainingWrapper,
  TimeRemainingTitle,
  RemainingTimeTitle,
} from "../Challenge/style";
import Trans from "../Trans";

const ExpirationDate = ({ expiration_date }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(expiration_date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      const hours = Math.floor((difference / 1000 / 60 / 60) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = { hours, minutes, seconds, difference };
    } else {
      timeLeft = { hours: 0, minutes: 0, seconds: 0, difference };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (timeLeft.difference <= 0) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <TimeRemainingWrapper
      data-transaction-name="timer"
      timeRemaining={
        timeLeft.difference / 1000 / 60 < 1 &&
        (timeLeft.minutes < 1 ||
          (timeLeft.minutes === 0 && timeLeft.seconds === 0))
      }
    >
      <div>
        <TimeRemainingTitle>
          <Trans message="remainingTime" />{" "}
          <RemainingTimeTitle>
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </RemainingTimeTitle>
          <TimeRemainingTitle />
        </TimeRemainingTitle>
      </div>
    </TimeRemainingWrapper>
  );
};

export default ExpirationDate;
