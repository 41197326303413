import { i18n, state } from "../../services";
import { HelperForChooseMethod } from "../../styles";
import testfile from "../../testfile";
import Header from "../Header";
import PaymentDetails from "../PaymentDetails";

const FormHeaderDefault = ({
  reference,
  description,
  detailedInfo,
  wholeNumber,
  decimal,
  currency,
  titleError,
  hasGradient,
  hasData,
  selectedMethod,
}) => {
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const hasError = !canReturnToMethods && !!titleError;

  return (
    <>
      <Header hasGradient={hasGradient} />
      {hasData ? (
        <PaymentDetails
          titleError={titleError}
          detailedInfo={detailedInfo}
          reference={reference}
          description={description}
          wholeNumber={wholeNumber}
          decimal={decimal}
          currency={currency}
        />
      ) : null}

      {!selectedMethod && !hasError ? (
        <HelperForChooseMethod
          onClick={() => {
            testfile.test();
          }}
        >
          {i18n.getMessage({ message: "chooseMethod" })}
        </HelperForChooseMethod>
      ) : null}
    </>
  );
};

export default FormHeaderDefault;
