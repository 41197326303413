import accountRequisites from "./account_requisites";
import bankCodeChallenge from "./bank_code_challenge";
import cryptoCurrencyNotActive from "./crypto_currency_not_active";
import selectAmountChallenge from "./select_amount_challenge";

export default {
  account_requisites: accountRequisites,
  crypto_currency_not_active: cryptoCurrencyNotActive,
  select_amount_challenge: selectAmountChallenge,
  bank_code_challenge: bankCodeChallenge,
};
