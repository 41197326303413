import axios from "axios";
import { HTTP_ERRORS } from "./constants";
import logger from "./logger";
import testData from "./testData";
import state from "./state";
import crypto from "./crypto";
import queryString from "query-string";
import { localStorageService } from ".";

const baseApiUrl =
  typeof window !== "undefined" ? `${window.location.origin}/api` : "/api";

export const notificationError = ({ error, reason }) => {
  let { status } = error || {};
  const { response } = error || {};
  let { statusText, status: responseStatus } = response || {};

  if (status !== 200 || error?.code === "ERR_NETWORK") {
    const supportedStatuses = Object.keys(HTTP_ERRORS);

    if (status && !supportedStatuses.includes(status?.toString())) {
      status = status < 500 ? 400 : 500;
    }

    if (
      !status &&
      responseStatus &&
      !supportedStatuses.includes(responseStatus?.toString())
    ) {
      responseStatus = responseStatus < 500 ? 400 : 500;
    }

    let errorMessage =
      HTTP_ERRORS[status?.toString()] ||
      HTTP_ERRORS[responseStatus?.toString()];

    const errorObject = {
      ...errorMessage,
      status: "error",
      statusText,
      isError: true,
    };

    if (error?.code === "ERR_NETWORK") {
      errorObject.title = "noConnectionTryAgain";
    } else if (reason) {
      errorObject.title = reason;
    } else if (errorMessage?.title) {
      errorObject.title = errorMessage.title;
    }

    return state.set({
      notification: {
        ...errorObject,
      },
    });
  }
};

const getHttpErrorMessage = (error, isNotification = false, reason = "") => {
  const { response } = error || {};
  let { status } = response || {};
  const { statusText } = response || {};

  const supportedStatuses = Object.keys(HTTP_ERRORS);

  if (!response) {
    if (isNotification && error?.code === "ERR_NETWORK") {
      return notificationError({ error, reason });
    }

    if (typeof window !== "undefined" && window.isRedirecting) {
      return state.set({
        status: "error",
        error: {
          isIdle: true,
        },
      });
    }

    return state;
  }

  if (status && !supportedStatuses.includes(status?.toString())) {
    status = status < 500 ? 400 : 500;
  }

  let errorMessage = HTTP_ERRORS[status?.toString()];

  const errorObject = {
    ...errorMessage,
    status,
    statusText,
    isError: true,
  };

  if (isNotification) {
    notificationError({ error, reason });
  }

  return state.set({
    status: "error",
    error: {
      ...errorObject,
    },
  });
};

const getInitData = ({ type, sessionId, signature }) => {
  const url = `${[baseApiUrl, type, sessionId, signature].join("/")}`;
  const params = {
    method: "get",
    url,
  };

  if (testData.isTestMode(sessionId, signature)) {
    testData.subscribePostMessage();

    return Promise.resolve(testData.get());
  }

  return axios(params)
    .then((response) => {
      if (response.data && response.data.status) {
        return response.data;
      }

      logger.error("No response data or status for get init data request");

      return Promise.reject({});
    })
    .catch((error) => {
      return Promise.resolve(
        getHttpErrorMessage(error, !!state.get()?.identifier),
      );
    });
};

const getConversion = (
  { conversion_currency, conversion_amount, pay_currency },
  { type, sessionId, signature },
) => {
  const url = [
    baseApiUrl,
    type,
    "exchange",
    "conversion",
    sessionId,
    signature,
  ].join("/");
  const data = {
    conversion_currency,
    conversion_amount,
    pay_currency,
  };
  const params = {
    method: "post",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("getConversion", data);
  }

  return axios(params).catch((error) => {
    return Promise.resolve(getHttpErrorMessage(error));
  });
};

const getInitDataCrypto = ({ sessionId, signature, updatedData }) => {
  let paramValue;
  let paramName;

  if (updatedData?.currency_amount) {
    paramName = "amount";
    paramValue = updatedData?.currency_amount;
  } else if (updatedData?.pay_amount) {
    paramName = "pay_amount";
    paramValue = updatedData?.pay_amount;
  }

  const queryStringParam =
    paramName && paramValue
      ? `?${queryString.stringify({ [paramName]: paramValue })}`
      : "";

  const url = `${[
    window.location.origin,
    "v1/crypto/address",
    sessionId,
    signature,
    queryStringParam,
  ].join("/")}`.replace(/\/$/, "");
  const params = {
    method: "get",
    url,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("getCryptoData", queryStringParam);
  }

  return axios(params).then((response) => {
    if (response.data) {
      crypto.set({
        ...response.data,
      });

      return response.data;
    }

    logger.error("No response data  for get init data crypto request");

    return Promise.reject({});
  });
};

const sendChallengeBankCodeData = (code) => {
  const { sessionId, signature } = state.getSession();
  const url = `${baseApiUrl}/payment/challenge/bank_code/${sessionId}/${signature}`;
  const data = { bank_code: code };

  if (testData.isTestMode(sessionId, signature)) {
    testData.set("challenge", "status");
    testData.set(
      {
        type: "p2c_phone",
        p2c_phone: {
          phone: "380982241283",
          receiver_customer_name: "Dart V.",
          bank_name: "Kazpost Bank",
        },
      },
      ["challenge"],
    );
  }

  return axios.post(url, data);
};

const sendFormData = (data, { type, sessionId, signature }) => {
  const url = `${[baseApiUrl, type, sessionId, signature].join("/")}`;
  const params = {
    method: "post",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("sendFormData", data);
  }

  return axios(params).catch((error) => {
    return Promise.resolve(getHttpErrorMessage(error, true, "submitError"));
  });
};

const removeCard = (id, { sessionId, signature }) => {
  const url = `${baseApiUrl}/payment/card-binding/${id}/${[
    sessionId,
    signature,
  ].join("/")}`;
  const params = {
    method: "delete",
    url,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("removeCard", "test");
  }

  return axios(params).catch((error) => error);
};

const setFingerprintZenData = (value) => {
  const { sessionId, signature } = state.getSession();
  const url = `${baseApiUrl}/payment/challenge/zen-fingerprint/${sessionId}/${signature}`;

  const data = {
    data: value,
  };

  const params = {
    method: "post",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("setFingerprintZenData", data);
  }

  return axios(params);
};

const trackEvent = (data, { sessionId, signature }) => {
  const url = `${baseApiUrl}/event/${[sessionId, signature].join("/")}`;
  const params = {
    method: "post",
    url,
    data,
    headers: {
      ...axios.defaults.headers.common,
    },
  };

  const sessionData = localStorageService.getSessionData(
    "localStorage",
    sessionId,
  );

  const { storageSessionId, storageReferrer } = sessionData;

  const currentSessionReferrer =
    storageSessionId === sessionId ? storageReferrer : null;

  if (currentSessionReferrer) {
    params.headers.Referrer = currentSessionReferrer;
  }

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("trackEvent", data);
  }

  return axios(params);
};

const validateAppleMerchant = (data, { sessionId, signature }) => {
  const url = `${baseApiUrl}/payment/apple/${[sessionId, signature].join("/")}`;
  const params = {
    method: "post",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("validateAppleMerchant", data);
  }

  return axios(params);
};

const sendApplePaymentToken = (data, { sessionId, signature }) => {
  const url = `${baseApiUrl}/payment/apple/${[sessionId, signature].join("/")}`;
  const params = {
    method: "patch",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("sendApplePaymentToken", data);
  }

  return axios(params);
};

const sendGooglePaymentToken = (data, { sessionId, signature }) => {
  const url = `${baseApiUrl}/payment/google/${[sessionId, signature].join(
    "/",
  )}`;

  const params = {
    method: "post",
    url,
    data,
  };

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("sendGooglePaymentToken", data);
  }

  return axios(params);
};

const submitChallengeForm = async ({
  sessionId,
  signature,
  values,
  challengeUrl,
  challengeType,
  currentMethod,
}) => {
  if (testData.isTestMode(sessionId, signature)) {
    console.log("Sending challenge data: ", { challengeUrl, values });

    return Promise.resolve(
      testData.handleChallengeOutcome(currentMethod, challengeType),
    );
  }

  await axios.post(challengeUrl, values);
};

const getBrandInfo = (sessionId, signature, hasRequestToOpenBrandInfo) => {
  const isTestMode = testData.isTestMode(sessionId, signature);
  const url = `/api/brand/info/${sessionId}/${signature}`;
  const openUrl = "/api/open-brand-info";
  const params = {
    method: "get",
    url: isTestMode || hasRequestToOpenBrandInfo ? openUrl : url,
  };

  const isLocalUrl = [
    "http://localhost:8080",
    "http://0.0.0.0:8080",
    "http://127.0.0.1:8080",
  ].includes(window.location.origin);

  if (isTestMode && isLocalUrl) {
    return Promise.resolve({
      support_email: null,
      brand_website: null,
      theme: "default", // noname, default, kombine
      logo_white: "/brands/asquad.png",
      color_scheme: "blue", // blue, darkBlue
      hpp_accent_colour: "#0062eb",
      hpp_background_colour: "#0062eb",
      hpp_loader_name: "AsquadLoader",
      hpp_main_font: "Rubik",
      hotjar_site_id: "someId",
    });
  }

  return axios(params)
    .then((response) => {
      if (response?.data) {
        return response.data;
      }

      return null;
    })
    .catch(async (error) => {
      if (!hasRequestToOpenBrandInfo) {
        return await getBrandInfo(sessionId, signature, true);
      }

      return Promise.resolve(getHttpErrorMessage(error));
    });
};

const resendChallenge = ({ sessionId, signature }) => {
  const url = `${baseApiUrl}/payment/challenge/otp/resend/${sessionId}/${signature}`;

  return axios.post(url);
};

const getDropdownList = ({ uri }) => {
  const { sessionId, signature } = state.getSession();
  const url = `${baseApiUrl}/${uri}/${sessionId}/${signature}`;

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("getBanksList");
  }

  return axios.get(url);
};

const prolongate = () => {
  const { sessionId, signature } = state.getSession();
  const url = `${baseApiUrl}/payment/prolong/${sessionId}/${signature}`;

  const params = {
    method: "post",
    url,
  };

  return axios(params);
};

const submitAccountRequisites = () => {
  const { sessionId, signature } = state.getSession();
  const url = `${baseApiUrl}/payment/challenge/confirm/${sessionId}/${signature}`;

  if (testData.isTestMode(sessionId, signature)) {
    return testData.testRequest("submitAccountRequisites");
  }

  return axios.post(url).catch((error) => {
    return Promise.resolve(getHttpErrorMessage(error, true, "submitError"));
  });
};

const getCoveryScript = (sessionId, signature) => {
  const url = `${baseApiUrl}/antifraud/covery-script/${sessionId}/${signature}`;

  return axios.get(url).then((response) => response.data);
};

export default {
  getInitData,
  sendFormData,
  removeCard,
  trackEvent,
  sendChallengeBankCodeData,
  validateAppleMerchant,
  sendApplePaymentToken,
  sendGooglePaymentToken,
  getHttpErrorMessage,
  getInitDataCrypto,
  getConversion,
  baseApiUrl,
  setFingerprintZenData,
  submitChallengeForm,
  resendChallenge,
  getBrandInfo,
  getDropdownList,
  prolongate,
  notificationError,
  submitAccountRequisites,
  getCoveryScript,
};
