import styled, { keyframes } from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const LoaderContainer = styled.div<{
  $hasBlur?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme?.mainBackGroundColor};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 24px;

  ${({ $hasBlur }) => {
    if ($hasBlur) {
      return `
        background: rgba(240, 244, 245, 0.3);
        backdrop-filter: blur(5px);
      `;
    }

    return ``;
  }}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  justify-content: center;
  padding: 24px;
`;

const rotating = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

export const AsquadLoaderContainer = styled.div<{
  $size?: "default" | "small";
}>`
  width: 64px;
  height: 64px;
  background: ${COLORS.DEFAULT_BLACK};
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotating} 1s linear infinite;

  ${({ $size }) => {
    if ($size === "small") {
      return `
        svg {
          height: 12px !important;
          width: 12px !important;
        }
      `;
    }

    return "";
  }}
`;
