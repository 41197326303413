import { useEffect, useContext } from "preact/hooks";
import { logger } from "../../services";
import failPageControl from "../../components/FailPage/FailPageControl";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";
import useApmTransaction from "../../hooks/useApmTransaction";
import useApmPageTracking from "../../hooks/useApmPageTracking";

const FailPage = () => {
  useApmPageTracking("FailPage");

  const context = useContext(Context);
  const { currentState, onGoBackButtonClick } = context;

  const { amount, currency, options, reference, request_id } =
    currentState || {};
  const { status_page, redirect_url } = options || {};
  const { enabled } = status_page || {};

  const { createSpan: createFailSpan, endTransaction: endFailTransaction } =
    useApmTransaction("FailFlow");

  const onButtonClick = () => {
    const span = createFailSpan("FailPage_ButtonClick");

    logger.addLabels({
      "session.sessionFailCancelUrl": redirect_url,
    });

    onGoBackButtonClick(redirect_url);

    span?.end();
    endFailTransaction();
  };

  useEffect(() => {
    const targetUrl = redirect_url;

    if (!targetUrl) {
      logger.error("Target fail page redirect URL not fount");

      return;
    }

    if (!enabled) {
      const span = createFailSpan("FailPage_AutoRedirect");

      logger.addLabels({
        "session.sessionAutoredirectFailUrl": redirect_url,
      });

      onGoBackButtonClick(redirect_url);

      span?.end();
      endFailTransaction();
    }
  }, [redirect_url, enabled]);

  const ControlFailPage =
    failPageControl[options?.theme] || failPageControl.default;

  useEffect(() => {
    if (!ControlFailPage) {
      logger.error("Page not found");
    }
  }, []);

  return (
    <Scroll>
      <ControlFailPage
        amount={amount}
        currency={currency}
        onButtonClick={onButtonClick}
        reference={reference}
        options={options}
        request_id={request_id}
        hasGoBack
      />
    </Scroll>
  );
};

export default FailPage;
