import { useContext } from "preact/hooks";
import { PAYMENT_DATA_ERRORS } from "../../services/constants";
import { Context } from "../../app";
import FormContainer from "../../components/MainPage/FormContainer";
import Scroll from "../../components/Scroll";
import { ContextUI } from "../../providers/UIProvider";
import useApmPageTracking from "../../hooks/useApmPageTracking";

const RepayPage = ({ sessionId, signature }) => {
  useApmPageTracking("RepayPage");

  const { currentState = {} } = useContext(Context);
  const { setNotificationData } = useContext(ContextUI);

  const supportedErrorCodes = Object.keys(PAYMENT_DATA_ERRORS);
  const defaultErrorCode = 1001;
  const { error } = currentState || {};

  const handleCopy = (value, title) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setNotificationData({ text: title, status: "success", isOpen: true });
  };

  let { error_code } = error || {};

  if (!error_code) {
    error_code = defaultErrorCode;
  }

  if (error_code && !supportedErrorCodes.includes(error_code?.toString())) {
    error_code = defaultErrorCode;
  }

  const errorMessage = error_code
    ? PAYMENT_DATA_ERRORS[error_code?.toString()]
    : {};

  return (
    <Scroll style={{ display: "flex", flexDirection: "column" }}>
      <FormContainer
        sessionId={sessionId}
        signature={signature}
        handleCopy={handleCopy}
        titleError={errorMessage.title}
      />
    </Scroll>
  );
};

export default RepayPage;
