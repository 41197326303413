export const CURRENCY_COUNTRY_MAPPING = [
  { currency: "AOA", countryName: "AO" },
  { currency: "BIF", countryName: "BI" },
  { currency: "BWP", countryName: "BW" },
  { currency: "CVE", countryName: "CV" },
  { currency: "DJF", countryName: "DJ" },
  { currency: "DZD", countryName: "DZ" },
  { currency: "ERN", countryName: "ER" },
  { currency: "ETB", countryName: "ET" },
  { currency: "GHS", countryName: "GH" },
  { currency: "GMD", countryName: "GM" },
  { currency: "GNF", countryName: "GN" },
  { currency: "KES", countryName: "KE" },
  { currency: "KMF", countryName: "KM" },
  { currency: "LRD", countryName: "LR" },
  { currency: "LSL", countryName: "LS" },
  { currency: "LYD", countryName: "LY" },
  { currency: "MAD", countryName: "MA" },
  { currency: "MGA", countryName: "MG" },
  { currency: "MRU", countryName: "MR" },
  { currency: "MUR", countryName: "MU" },
  { currency: "MWK", countryName: "MW" },
  { currency: "MZN", countryName: "MZ" },
  { currency: "NAD", countryName: "NA" },
  { currency: "NGN", countryName: "NG" },
  { currency: "RWF", countryName: "RW" },
  { currency: "SCR", countryName: "SC" },
  { currency: "SDG", countryName: "SD" },
  { currency: "SLL", countryName: "SL" },
  { currency: "SOS", countryName: "SO" },
  { currency: "STN", countryName: "ST" },
  { currency: "SZL", countryName: "SZ" },
  { currency: "TND", countryName: "TN" },
  { currency: "TZS", countryName: "TZ" },
  { currency: "UGX", countryName: "UG" },
  { currency: "XAF", countryName: "GA" },
  { currency: "XAF", countryName: "CM" },
  { currency: "XAF", countryName: "CF" },
  { currency: "XAF", countryName: "TD" },
  { currency: "XAF", countryName: "GQ" },
  { currency: "XOF", countryName: "BJ" },
  { currency: "XOF", countryName: "BF" },
  { currency: "XOF", countryName: "GW" },
  { currency: "XOF", countryName: "CI" },
  { currency: "XOF", countryName: "ML" },
  { currency: "XOF", countryName: "NE" },
  { currency: "XOF", countryName: "SN" },
  { currency: "XOF", countryName: "TG" },
  { currency: "ZAR", countryName: "ZA" },
  { currency: "ZMW", countryName: "ZM" },
  { currency: "ZWL", countryName: "ZW" },
];
