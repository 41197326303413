import { useEffect, useState, useRef, useContext } from "preact/hooks";
import { useConnection } from "../services/hooks";
import { i18n, logger } from "../services";
import state, { changeLocale } from "../services/state";
import { ContextUI } from "../providers/UIProvider";

type TState = {
  options?: {
    locale?: string;
  };
};

const useLocaleManager = (initialLocale?: string) => {
  const [locale, setLocaleState] = useState<string>(initialLocale || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const retryTimer = useRef<NodeJS.Timeout | null>(null);

  const hasConnection = useConnection();

  const { handleCloseModal } = useContext(ContextUI);

  const loadLocale = async (localeToLoad: string) => {
    setIsLoading(true);
    setHasError(false);

    try {
      await changeLocale(localeToLoad).then(() => {
        if (retryTimer.current) {
          handleCloseModal();
        }
      });
      setLocaleState(localeToLoad);
    } catch (error) {
      setHasError(true);
      logger.error(`Failed to load locale: ${localeToLoad}`, { error });

      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasError && hasConnection && locale) {
      retryTimer.current = setTimeout(() => {
        loadLocale(locale);
      }, 1000);
    }

    return () => {
      if (retryTimer.current) {
        clearTimeout(retryTimer.current);
      }
    };
  }, [hasError, hasConnection]);

  useEffect(() => {
    if (locale) {
      loadLocale(locale);
    } else if (
      !i18n.getIsLocaleSet() &&
      (state.get() as TState)?.options?.locale
    ) {
      loadLocale((state.get() as TState)?.options?.locale || "");
    }
  }, [locale, (state.get() as TState)?.options?.locale]);

  const setLocale = (newLocale: string): Promise<void> => {
    if (retryTimer.current) {
      clearTimeout(retryTimer.current);
    }
    setLocaleState(newLocale);
    return loadLocale(newLocale);
  };

  return {
    locale,
    isLoading,
    hasError,
    setLocale,
  };
};

export default useLocaleManager;
