import FormContainer from "./FormContainer";
import Scroll from "../Scroll";

const MainPage = ({ sessionId, signature, handleCopy }) => {
  return (
    <Scroll
      style={{ display: "flex", flexDirection: "column" }}
      data-transaction-name="page-main"
    >
      <FormContainer
        sessionId={sessionId}
        signature={signature}
        handleCopy={handleCopy}
      />
    </Scroll>
  );
};

export default MainPage;
