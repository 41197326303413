/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, useContext } from "preact/hooks";
import { state, logger, localStorageService } from "../../services";
import successPageControl from "../../components/SuccessPage/SuccessPageControl";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";
import useApmTransaction from "../../hooks/useApmTransaction";
import useApmPageTracking from "../../hooks/useApmPageTracking";

const SuccessPage = () => {
  useApmPageTracking("SuccessPage");

  const context = useContext(Context);
  const { style, currentState, onGoBackButtonClick, matchesData } = context;
  const { sessionId } = matchesData?.matches || {};
  const { options } = currentState || {};
  const { status_page, redirect_url } = options || {};
  const { redirect_after_sec, enabled } = status_page || {};
  const [countdown, setCountdown] = useState(redirect_after_sec);
  const {
    createSpan: createSuccessSpan,
    endTransaction: endSuccessTransaction,
  } = useApmTransaction("CancelSuccessFlow");

  const sessionData = localStorageService.getSessionData(
    "localStorage",
    sessionId,
  );
  const { storageReferrer } = sessionData;

  const currentSessionReferrer = storageReferrer || null;

  const onButtonClick = () => {
    const span = createSuccessSpan("SuccessPage_ButtonClick");

    logger.addLabels({
      "session.sessionSuccessRedirectUrl": redirect_url,
    });

    onGoBackButtonClick(redirect_url);

    span?.end();
    endSuccessTransaction();
  };

  useEffect(() => {
    const targetUrl = redirect_url || currentSessionReferrer;

    if (!targetUrl) {
      logger.error("Target success page redirect URL not fount");

      return;
    }

    if (countdown === 0 || !enabled) {
      const span = createSuccessSpan("SuccessPage_AutoRedirect");

      logger.addLabels({
        "session.sessionAutoredirectCancelUrl": redirect_url || storageReferrer,
      });

      span?.end();
      endSuccessTransaction();

      state.changeLocation(targetUrl, true);
    }

    let timer;

    if (countdown) {
      timer = setInterval(
        () => setCountdown(countdown > 0 ? countdown - 1 : countdown),
        1000,
      );
    }

    return () => clearInterval(timer);
  }, [
    countdown,
    redirect_url,
    redirect_after_sec,
    enabled,
    currentSessionReferrer,
  ]);

  if (!style || !enabled) {
    return;
  }

  const ControlSuccess =
    successPageControl[options?.theme] || successPageControl.default;

  useEffect(() => {
    if (!ControlSuccess) {
      logger.error("Page not found");
    }
  }, []);

  return (
    <Scroll>
      <ControlSuccess countdown={countdown} onButtonClick={onButtonClick} />
    </Scroll>
  );
};

export default SuccessPage;
