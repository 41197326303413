import { AsquadLoaderContainer } from "./style";
import AsquadLoaderIcon from "../../assets/icons/ui/AsquadLoaderIcon";

const AsquadLoader = ({ size }) => {
  return (
    <AsquadLoaderContainer $size={size}>
      <AsquadLoaderIcon />
    </AsquadLoaderContainer>
  );
};

export default AsquadLoader;
