import { useRef, useContext } from "preact/hooks";
import Footer from "../Footer";
import Trans from "../Trans";
import IconCopy from "../../assets/icons/iconCopyCombine";
import InfoIcon from "../../assets/icons/infoIcon";
import {
  SuccessPageBlockWrapper,
  SuccessPageWrapper,
  TransactionInfoFirstBlock,
  PaymentTitle,
  PaymentInfoBlock,
  TransactionTitle,
  TransactionInfoSecondBlock,
  TransactionInfoErrorBlockKomb,
  TransactionDecimalTitle,
  TransactionSumTitle,
  ErrorTitle,
  TransactionIDTitle,
  TransactionRefTitle,
  BaseButton,
  IconColorWrapper,
  LogoBrandHeader,
} from "../../styles";
import { PaymentMethodTitle } from "../Challenge/style";
import colorSchema from "../../colorThemes/colorSchemes";
import brand from "../../services/brand";
import { ContextUI } from "../../providers/UIProvider";

const FailPageKombine = ({
  currency,
  amount,
  reference,
  onButtonClick,
  title,
  description,
  request_id,
  hasGoBack = false,
}) => {
  const parts = amount?.toString()?.split(".");
  const wholeNumber = parts && parts[0];
  const decimal = parts && parts[1];

  const brandData = brand.getBrand();
  const errorIcon = colorSchema?.[brandData?.color_scheme]?.errorIcon;

  const transactionIdRef = useRef(null);
  const { setNotificationData } = useContext(ContextUI);

  const handleCopy = (value, title) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setNotificationData({ text: title, status: "success", isOpen: true });
  };

  return (
    <SuccessPageBlockWrapper>
      <SuccessPageWrapper>
        <TransactionInfoFirstBlock padding="24px 16px">
          <div style={{ position: "relative" }}>
            {errorIcon && (
              <LogoBrandHeader
                style={{
                  width: "40px",
                  height: "40px",
                }}
                src={`${errorIcon}`}
              />
            )}
          </div>

          <PaymentTitle mb="0px" fs="20px">
            <Trans message={title || "transactionFailed"} />
          </PaymentTitle>
        </TransactionInfoFirstBlock>
        <TransactionInfoErrorBlockKomb background="#FFE2E7">
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoIcon />
            <ErrorTitle ml="12px">
              <Trans message={description || "errorTtl"} />
            </ErrorTitle>
          </div>
        </TransactionInfoErrorBlockKomb>

        <TransactionInfoSecondBlock
          background="#05224C"
          padding="16px"
          margin="24px 16px 0px 16px"
          borderRadius="8px"
          height="auto"
        >
          <PaymentMethodTitle $color="#FFFFFFE5" $mb="16px" $fs="16px">
            Transaction detail:
          </PaymentMethodTitle>
          {wholeNumber && (
            <PaymentInfoBlock alignItems="center" mb="20px">
              <TransactionTitle fs="16px" $color="#FFFFFF99">
                <Trans message="TotalDue" />
              </TransactionTitle>
              <span>
                <TransactionSumTitle fs="20px" color="#FFFFFFE5">
                  {wholeNumber}
                </TransactionSumTitle>
                {decimal && (
                  <TransactionDecimalTitle fs="20px" color="#FFFFFFE5">
                    .{decimal}
                  </TransactionDecimalTitle>
                )}
                <TransactionDecimalTitle
                  fs="20px"
                  style={{ marginLeft: "8px" }}
                  color="#FFFFFF99"
                >
                  {currency}{" "}
                </TransactionDecimalTitle>
              </span>
            </PaymentInfoBlock>
          )}
          {reference && (
            <PaymentInfoBlock mb="20px">
              <TransactionIDTitle color="#FFFFFF99" fs="12px">
                <Trans message="transactionIdTitle" />
              </TransactionIDTitle>
              <span style={{ display: "flex", alignItems: "flex-start" }}>
                <TransactionRefTitle
                  fs="12px"
                  color="#FFFFFFE5"
                  ref={transactionIdRef}
                >
                  {reference}
                </TransactionRefTitle>{" "}
                <span
                  onClick={() => handleCopy(reference, "transactionIdCopy")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <IconColorWrapper color="#FFFFFF">
                    <IconCopy />
                  </IconColorWrapper>
                </span>
              </span>
            </PaymentInfoBlock>
          )}
          {request_id && (
            <PaymentInfoBlock mb="0px">
              <TransactionIDTitle color="#FFFFFF99" fs="12px">
                <Trans message="orderTittle" />
              </TransactionIDTitle>
              <span style={{ display: "flex", alignItems: "flex-start" }}>
                <TransactionRefTitle
                  fs="12px"
                  color="#FFFFFFE5"
                  ref={transactionIdRef}
                >
                  {request_id}
                </TransactionRefTitle>{" "}
              </span>
            </PaymentInfoBlock>
          )}
        </TransactionInfoSecondBlock>

        {hasGoBack && (
          <BaseButton
            textColor="#fff"
            onClick={onButtonClick}
            style={{
              marginTop: "auto",
              background: "linear-gradient(#0629DF, #00AAFF)",
              border: "none",
              height: "48px",
            }}
          >
            <Trans message="TRY AGAIN" />
          </BaseButton>
        )}
        <Footer margin="0 auto" />
      </SuccessPageWrapper>
    </SuccessPageBlockWrapper>
  );
};

export default FailPageKombine;
