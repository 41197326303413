/* eslint-disable no-use-before-define */
import { ThemeKeys } from "../constants/themeConstants";
import favicon_error from "../assets/icons/favicon_error.ico";
import defaultFavicon from "../assets/favicon.ico";

const defaultThemeConfig = {
  isHideFormHeaderDescription: false,
  showPublicAgreement: false,
  cardIconPass: false,
  showErrorPopup: false,
  showIps: false,
  showCustomFooter: true,
  innerInputRememberMeShow: true,
  beyondTheFieldsRememberMeShow: false,
  notDefaultPayTittle: false,
  underFormButtonRememberMe: false,
  cardHolderValidation: "default",
  innerFormIpsLogo: false,
  replacedFormPaymentAmountButton: true,
  renderInputDoubleDates: false,
  isHideBackToActionTitle: false,
  isHideTransactionId: false,
  isHideDescription: false,
  isHideTranslation: false,
  isShowInnerLine: true,
  isShowCurrencyCopy: false,
  innerCardListManageTitle: false,
  cardHolderInputPlaceHolder: "nameOnCard",
  formButtonTitle: "pay",
  formButtonCurrency: false,
  showDateTooltip: true,
  cardInputPlaceHolder: "•••• •••• •••• ••••",
  showTextSavedCardAction: false,
  inputCardLabel: "pan",
  inputCardHolderLabel: "nameOnCard",
  inputDateLabel: "asquad.exp",
};

export const themes = {
  [ThemeKeys.DEFAULT]: defaultThemeConfig,
  [ThemeKeys.KOMBINE]: {
    ...defaultThemeConfig,
    isHideBackToActionTitle: true,
    cardHolderInputPlaceHolder: "John Doe",
    cardInputPlaceHolder: "0000 0000 0000 0000",
    formButtonTitle: "DEPOSIT",
    showTextSavedCardAction: true,
    cardHolderValidation: "kombine",
    innerInputRememberMeShow: false,
    innerCardListManageTitle: true,
    showDateTooltip: false,
    formButtonCurrency: true,
    isHideTranslation: true,
    isHideDescription: true,
    isShowInnerLine: false,
    inputCardLabel: "Card number:",
    inputCardHolderLabel: "Name on card:",
    inputDateLabel: "Expires date:",
    getDisableButtonStatus: ({ values, errors }) => {
      const everyFieldsAreTyped = ["card", "date", "cvv", "cardHolder"].every(
        (field) => !!values[field],
      );

      return !(everyFieldsAreTyped && Object.keys(errors).length === 0);
    },
    getFieldOrder: ({ field, index }) => {
      if (field.name === "cardHolderName") {
        return 1;
      }

      if (field.name === "cardNumber") {
        return 0;
      }

      return index;
    },
  },
  [ThemeKeys.NONAME]: {
    ...defaultThemeConfig,
  },
  [ThemeKeys.MUNZEN]: {
    ...defaultThemeConfig,
  },
  [ThemeKeys.N1]: {
    ...defaultThemeConfig,
    title: "n1betpay.ng",
    isHideBackToActionTitle: true,
    isHideTransactionId: true,
    isHideDescription: true,
    isShowCurrencyCopy: true,
    totalDueTitle: "amount",
  },
  [ThemeKeys.N1_NONAME]: {
    ...defaultThemeConfig,
    title: "n1betpay.ng",
    isHideBackToActionTitle: true,
    isHideTransactionId: true,
    isHideDescription: true,
    isShowCurrencyCopy: true,
    totalDueTitle: "amount",
  },
  [ThemeKeys.SETTLEPAY]: {
    ...defaultThemeConfig,
    title: "Settlepay",
  },
  [ThemeKeys.SETTLEPAY_NONAME]: {
    ...defaultThemeConfig,
    title: "Settlepay",
  },
  [ThemeKeys.ERROR]: {
    favicon: favicon_error,
    title: "payment error",
  },
};

const colorSchemes = {
  [ThemeKeys.N1]: "blackGreen",
  [ThemeKeys.N1_NONAME]: "blackGreen",
  [ThemeKeys.SETTLEPAY]: "purple",
  [ThemeKeys.SETTLEPAY_NONAME]: "purple",
  [ThemeKeys.KOMBINE]: "darkBlue",
};

const logos = {
  [ThemeKeys.N1]: "/brands/n1.png",
  [ThemeKeys.N1_NONAME]: "",
  [ThemeKeys.MUNZEN]: "/brands/munzenLogo.png",
  [ThemeKeys.SETTLEPAY]: "/brands/settlepayLogo.png",
  [ThemeKeys.SETTLEPAY_NONAME]: "",
};

const favicons = {
  [ThemeKeys.N1]: "/brands/n1_favicon.png",
  [ThemeKeys.SETTLEPAY]: "/brands/settlepayFavicon.png",
};

const getFaviconElement = () => {
  if (typeof document !== "undefined") {
    let link = document?.querySelector("link[rel~='icon']");

    if (!link) {
      link = document?.createElement("link");
      link.rel = "icon";
      document?.getElementsByTagName("head")[0].appendChild(link);
    }

    return link;
  }

  return null;
};

const setFaviconHref = (href) => {
  const faviconElement = getFaviconElement();

  faviconElement.href = href;
};

const setTitle = (title) => {
  if (typeof document !== "undefined") {
    document.title = title;
  }
};

const getThemeName = (themeName) => {
  return themes[themeName] || themes[ThemeKeys.DEFAULT];
};

export const getThemeConfig = (themeName) => {
  return themes[themeName] || themes[ThemeKeys.DEFAULT];
};

export const getColorScheme = (themeName) => {
  return colorSchemes[themeName];
};

export const getLogo = (themeName) => {
  return logos[themeName];
};

export const getTitle = (themeName, brandData) => {
  return themes?.[themeName]?.title || brandData?.brand_name || "Payment";
};

export const loadFontByUrl = (url) => {
  if (typeof document !== "undefined") {
    // Check if a link with the same href already exists
    let existingLink = document?.querySelector(`link[href="${url}"]`);

    if (!existingLink) {
      let link = document?.createElement("link");
      link.href = url;
      link.rel = "stylesheet";
      document?.head.appendChild(link);
    }
  }
};

export default {
  getStyle: async (themeName, sessionId, signature, brandData) => {
    themeName = themeName || "default";

    const currentThemeName = themeName;
    let faviconHref;

    themeName = themeName || "default";

    if (currentThemeName?.includes("noname") || !currentThemeName) {
      faviconHref = defaultFavicon;
    } else if (favicons[themeName]) {
      faviconHref = favicons[themeName];
    } else {
      faviconHref = `/api/brand/favicon/${sessionId}/${signature}`;
    }

    const title = getTitle(themeName, brandData);

    try {
      setFaviconHref(faviconHref);
      setTitle(title);

      return {
        themeName,
        faviconHref,
        title,
      };
    } catch (err) {
      return console.log(err);
    }
  },
  getThemeName,
  getThemeConfig,
  getColorScheme,
  getLogo,
  loadFontByUrl,
};
