import { useState, useEffect, useContext } from "preact/hooks";
import challengeFormConfig from "./ChallengeFormConfig";
import { api, processing, localStorageService } from "../../services";
import FormInputs from "./ChallengeFormInputs";
import { ContextUI } from "../../providers/UIProvider";
import { Context } from "../../app";
const LAST_CHALLENGE_TYPE_KEY = "lastChallengeType";

const ChallengeForm = ({
  challengeType,
  sessionId,
  signature,
  challengeUrl,
  onFormSubmit,
  onFormSubmitSuccess,
  onFormSubmitFail,
  onFormSubmitFinally,
  currentMethod,
  challenge,
  submitButtonText,
}) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [isReadyForSubmit, setIsReadyForSubmit] = useState(false);
  const { currentState } = useContext(Context);
  const isLoading = currentState?.loadingData?.status;
  const { setNotificationData } = useContext(ContextUI);
  const [currentSessionChallengeType, setCurrentSessionChallengeType] =
    useState(null);

  useEffect(() => {
    try {
      const storedData = localStorageService.getSessionData(
        "sessionStorage",
        sessionId,
      )?.[LAST_CHALLENGE_TYPE_KEY];

      setCurrentSessionChallengeType(storedData);
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
      setCurrentSessionChallengeType(null);
    }
  }, []);

  useEffect(() => {
    const newValues = Object.keys(challengeFormConfig).reduce(
      (acc, fieldName) => {
        const fieldConfig = challengeFormConfig[fieldName];

        if (
          fieldConfig.supportedChallengeTypes &&
          !fieldConfig.supportedChallengeTypes.includes(challengeType)
        ) {
          return acc;
        }

        acc[fieldConfig.name] = "";

        return acc;
      },
      {},
    );

    setValues(newValues);
  }, [challengeType, currentSessionChallengeType]);

  const isMatchedSessionChallengeCurrentType =
    currentSessionChallengeType &&
    currentSessionChallengeType === challengeType &&
    challengeType;

  useEffect(() => {
    setIsReadyForSubmit(
      Object.values(values)?.filter((val) => !!val).length > 0,
    );
  }, [values]);

  const handleSubmit = () => {
    const renderedFields = Object.values(challengeFormConfig).filter((field) =>
      field.supportedChallengeTypes.includes(challengeType),
    );

    renderedFields.forEach((field) => {
      field.isActive = true;
    });

    const submitData = Object.keys(values)?.reduce((acc, item) => {
      if (typeof values[item] === "object") {
        const configField = renderedFields.find((field) => field.name === item);

        if (configField && configField.inputOptions.key) {
          acc[item] = values[item][configField.inputOptions?.key];
        }
      } else {
        acc[item] = values[item];
      }

      return acc;
    }, {});

    const validationResults = processing.validate({
      values: submitData || values,
      errors,
      method: "challenge",
      fields: renderedFields,
      isManuallyTriggered: true,
    });

    if (validationResults) {
      localStorageService.setSessionData("sessionStorage", sessionId, {
        [LAST_CHALLENGE_TYPE_KEY]: challengeType,
      });

      if (typeof onFormSubmit === "function") {
        onFormSubmit();
      }

      api
        .submitChallengeForm({
          values: submitData || values,
          sessionId,
          signature,
          challengeUrl,
          currentMethod,
          challengeType,
        })
        .then((data) => {
          if (["otp", "pin"].includes(challengeType)) {
            setNotificationData({
              text: "code_sent_successfully",
              status: "success",
              isOpen: true,
            });
          }

          if (typeof onFormSubmitSuccess === "function") {
            onFormSubmitSuccess(data);
          }
        })
        .catch((data) => {
          if (typeof onFormSubmitFail === "function") {
            onFormSubmitFail(data);
          }
        })
        .finally((data) => {
          if (typeof onFormSubmitFinally === "function") {
            onFormSubmitFinally(data);
          }

          sessionStorage.setItem(LAST_CHALLENGE_TYPE_KEY, challengeType);
        });
    } else {
      processing
        .getFieldsErrors({ fields: renderedFields, values: submitData })
        .forEach(({ key, value }) => {
          setErrors((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      isMatchedSessionChallengeCurrentType &&
      ["otp", "blik_code"].includes(challengeType)
    ) {
      setNotificationData({
        text: "incorrect_value",
        status: "fail",
        isOpen: true,
      });
    }
  }, [isMatchedSessionChallengeCurrentType, isLoading]);

  return (
    <>
      <FormInputs
        challengeFormConfig={challengeFormConfig}
        challengeType={challengeType}
        challenge={challenge}
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        sessionId={sessionId}
        signature={signature}
        handleSubmit={handleSubmit}
        isDisabled={!isReadyForSubmit}
        submitButtonText={submitButtonText}
      />
    </>
  );
};

export default ChallengeForm;
