import { PreactDOMAttributes } from "preact";
import { i18n } from "../services";

interface IProps {
  message: string;
  children?: PreactDOMAttributes;
  withoutWrapper?: boolean;
}

const Trans = ({ message, children, withoutWrapper = false }: IProps) => {
  // @ts-ignore
  const content = i18n.getMessage({ message, children });

  if (withoutWrapper) {
    return <>{content}</>;
  }

  return <span>{content}</span>;
};

export default Trans;
