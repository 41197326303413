import logger from "./logger";

const SESSION_STORAGE_KEY = "sessionData";

const readStorage = (storageType, key = SESSION_STORAGE_KEY) => {
  try {
    const storage =
      storageType === "localStorage" ? localStorage : sessionStorage;

    return JSON.parse(storage.getItem(key)) || {};
  } catch (error) {
    logger.error(`Failed to read from ${storageType} key: ${key}`, { error });

    return {};
  }
};

const writeStorage = (storageType, data, key = SESSION_STORAGE_KEY) => {
  try {
    const storage =
      storageType === "localStorage" ? localStorage : sessionStorage;
    const sanitizedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }

      return acc;
    }, {});
    storage.setItem(key, JSON.stringify(sanitizedData));
  } catch (error) {
    logger.error(`Failed to write to ${storageType} key: ${key}`, { error });
  }
};

export const setSessionData = (storageType, sessionId, data) => {
  if (typeof window === "undefined") return;

  const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
    if (value != null) acc[key] = value;

    return acc;
  }, {});

  if (Object.keys(filteredData).length === 0) return;

  const storedData = readStorage(storageType);
  const sessionData = storedData[sessionId] || {};

  Object.assign(sessionData, filteredData, { storageSessionId: sessionId });
  storedData[sessionId] = sessionData;

  if (Object.keys(storedData).length > 10) {
    delete storedData[Object.keys(storedData)[0]];
  }

  writeStorage(storageType, storedData);
};

export const getSessionData = (storageType, sessionId) => {
  const data = readStorage(storageType);

  return data[sessionId] || {};
};

export default {
  setSessionData,
  getSessionData,
};
