import {
  PopupActionTitle,
  PopupActionSubTitle,
  PopupIcon,
  PopupArrowIcon,
} from "../../../styles";
import arrowRightImg from "../../../assets/icons/arrowRignt.svg";
import PaymentMethodsLogo from "../../PaymentMethods/PaymentMethodsLogo";
import { formatCryptoCurrency } from "../../../constants/CryptoCurrencyIcon";
import Trans from "../../Trans";
import { PopupContentActionWrapper } from "../style";

const ModalItem = ({
  isActive,
  handleClick,
  name,
  icon,
  withoutIcon,
  arrowIcon,
  arrowAction,
  iconWidth = "24px",
  title,
  subTitle,
  children,
  hasArray = true,
  isBankCode,
  dataRole,
  method,
  channel,
}) => {
  const handleArrowClick = (e) => {
    if (arrowAction) {
      e.stopPropagation();

      arrowAction();
    }
  };

  const getFormattedTitle = (method, title) => {
    const formattedTitle = title?.replace(/_/g, " ");

    return method === "crypto"
      ? formatCryptoCurrency(formattedTitle, true)
      : formattedTitle;
  };

  return (
    <PopupContentActionWrapper
      $isActive={isActive}
      onClick={handleClick}
      data-role={dataRole}
    >
      {!withoutIcon && (
        <PopupIcon iconWidth={iconWidth}>
          {icon ? (
            <img src={icon} alt="icon" />
          ) : (
            <PaymentMethodsLogo
              selectedMethod={name}
              isBankCode={isBankCode}
              coloredIcon
              method={method}
              channel={channel}
            />
          )}
        </PopupIcon>
      )}
      {children || (
        <PopupActionTitle>
          <Trans message={getFormattedTitle(method, title)} />{" "}
          {subTitle && <PopupActionSubTitle>({subTitle})</PopupActionSubTitle>}
        </PopupActionTitle>
      )}
      {hasArray && (
        <PopupArrowIcon
          style={{
            marginLeft: "auto",
          }}
        >
          <img
            src={arrowIcon || arrowRightImg}
            alt="Arrow"
            onClick={handleArrowClick}
          />
        </PopupArrowIcon>
      )}
    </PopupContentActionWrapper>
  );
};

export default ModalItem;
