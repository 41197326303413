import {
  FormInputContainer,
  FormInput,
  InvalidCardErrorTitle,
  HintCardFieldTitle,
} from "../../styles";
import { i18n } from "../../services";
import ArrowIcon from "../../assets/icons/ui/arrow";
import { COLORS } from "../../constants/themeConstants";
import { FormInputIconWrapper } from "./style";

const CustomerInputDefault = ({
  nameKey,
  value,
  placeholder,
  autocomplete,
  onInput,
  onBlur,
  setFocused,
  focused,
  autofocus,
  error,
  errorChecking,
  inputRef,
  validationHint,
  type,
  isDropdown,
}) => {
  return (
    <>
      <FormInputContainer>
        <FormInput
          value={value}
          onInput={onInput}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          $focused={focused}
          autofocus={autofocus}
          $errorCard={errorChecking()}
          id={nameKey}
          type={type || "text"}
          autocomplete={autocomplete}
          placeholder={i18n.getMessage({ message: placeholder })}
          ref={inputRef}
          data-transaction-name={`CustomerInput-${nameKey}`}
          isDropdown={isDropdown}
        />
        {isDropdown && (
          <FormInputIconWrapper>
            <ArrowIcon color={COLORS.DEFAULT_BLACK} />
          </FormInputIconWrapper>
        )}
      </FormInputContainer>
      {errorChecking() ? (
        <InvalidCardErrorTitle data-transaction-name={`field-error-${nameKey}`}>
          {i18n.getMessage({ message: error })}
        </InvalidCardErrorTitle>
      ) : (
        validationHint && (
          <HintCardFieldTitle>
            {i18n.getMessage({ message: validationHint })}
          </HintCardFieldTitle>
        )
      )}
    </>
  );
};

export default CustomerInputDefault;
