import Trans from "../Trans";
import { FormPayButton, TransactionSumTitle } from "../../styles";
import { state } from "../../services";

const ChallengeFormSubmitButton = ({
  handleSubmit,
  isDisabled,
  submitButtonText,
}) => {
  return (
    <FormPayButton
      name="cardFormPayButton"
      type="submit"
      onClick={handleSubmit}
      style={{ width: "100%" }}
      disabled={isDisabled}
      isInIframe={state.isInIframe()}
    >
      <TransactionSumTitle>
        <Trans message={submitButtonText || "submit"} />
      </TransactionSumTitle>
    </FormPayButton>
  );
};

export default ChallengeFormSubmitButton;
