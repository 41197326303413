import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const LanguageItemWrapper = styled.div`
  & img {
    border-radius: 50%;
  }
`;

export const PopupContentActionWrapper = styled.div<{
  $isActive?: boolean;
}>`
  padding: 12px 16px;
  border-radius: 10px;
  height: 48px;
  background-color: ${({ $isActive }) =>
    $isActive ? COLORS.BlUE_LIGHT : "#f0f4f5"};
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  & > img {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    object-fit: cover;
  }

  &:hover {
    background-color: ${COLORS.BlUE_LIGHT};
  }
`;
