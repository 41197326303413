import { StyledLanguageButton, StyledCurrentLanguageIcon } from "../../styles";
import { useLogo } from "../../services/hooks";
import { i18n, state } from "../../services";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import LanguageModal from "../Modals/LanguageModal";

const FormHeaderLanguageAction = () => {
  const { setModalData } = useContext(ContextUI);

  const currentState: { options?: { theme?: string } } = state.get();
  const options = currentState.options;
  const logoIcon = useLogo(options?.theme);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      // @ts-ignore
      content: <LanguageModal />,
      title: "chooseLanguage",
      isFullView: i18n.getLanguageConfig()?.length > 4,
    });
  };

  return (
    // @ts-ignore
    <StyledLanguageButton $hasLogo={logoIcon}>
      <StyledCurrentLanguageIcon
        onClick={handleOpen}
        src={`/flags/${i18n.getCountryCode()?.toString()?.toLowerCase()}.svg`}
      />
    </StyledLanguageButton>
  );
};

export default FormHeaderLanguageAction;
