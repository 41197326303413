import { PreactDOMAttributes } from "preact";
import useDynamicImport from "../../hooks/useDynamicImport";
import { useCallback } from "preact/hooks";

interface AsyncComponentProps {
  importFn: (url: string) => Promise<any>;
  name: string;
  fallback?: PreactDOMAttributes | null;
  children?:
    | PreactDOMAttributes
    | ((LoadedComponent: any) => PreactDOMAttributes);
}

const AsyncComponent = ({
  importFn,
  name,
  fallback = null,
  children,
}: AsyncComponentProps) => {
  const memoizedImportFn = useCallback(
    (timestamp?: number) => {
      return importFn(
        `${timestamp ? `?timestamp=${timestamp?.toString()}` : ""}`,
      );
    },
    [importFn, name],
  );

  const LoadedComponent = useDynamicImport({
    fn: memoizedImportFn,
    name,
  });

  if (!LoadedComponent) {
    return fallback;
  }

  if (typeof children === "function") {
    return children(LoadedComponent);
  }

  return LoadedComponent;
};

export default AsyncComponent;
