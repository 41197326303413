import { useState, useEffect } from "preact/hooks";
import { state, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import CardHolderInputControl from "./CardHolderInput/index.js";
import Trans from "./Trans";
import { validateCardHolder } from "../constants/cardHolderInputValidate.js";
import {
  FormDescription,
  InputFormActionWrapper,
  FormInputContainerWrapper,
} from "../styles";

const CardHolderInput = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
}) => {
  const [focused, setFocused] = useState(false);

  const options = state.get().options;
  const currentTheme = theme.getThemeConfig(options?.theme);

  const ThemedCardHolderInput =
    CardHolderInputControl[currentTheme] || CardHolderInputControl.default;

  useEffect(() => {
    if (!ThemedCardHolderInput) {
      logger.error("Component not found");
    }
  }, []);

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev.cardHolder;

      return prev;
    });
  };

  const onInput = (e) => {
    let val = e.target.value.replace(/[^\p{L}\s'`~.-]/gu, "");

    if (validateCardHolder(currentTheme?.cardHolderValidation, val)) {
      clearErrors();
    }

    setValues((prev) => ({ ...prev, cardHolder: val }));
  };

  const onBlur = () => {
    setFocused(false);

    if (values.cardHolder.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        cardHolder: CONST_ERROR_LIST.required,
      }));
    }

    if (
      !validateCardHolder(currentTheme?.cardHolderValidation, values.cardHolder)
    ) {
      return setErrors((prev) => ({
        ...prev,
        cardHolder: CONST_ERROR_LIST.wrongNameCard,
      }));
    }

    return clearErrors();
  };

  const errorChecking = () => {
    if (!errors.cardHolder) {
      return false;
    }

    return !focused;
  };

  if (!ThemedCardHolderInput) {
    return;
  }

  return (
    <InputFormActionWrapper>
      <FormDescription $errorChecking={errorChecking()} $focused={focused}>
        <Trans message={currentTheme?.inputCardHolderLabel} />
      </FormDescription>
      <FormInputContainerWrapper>
        <ThemedCardHolderInput
          values={values}
          onInput={onInput}
          onBlur={onBlur}
          focused={focused}
          setFocused={setFocused}
          errorChecking={errorChecking}
          inputRef={inputRef}
          errors={errors}
          placeholder={currentTheme?.cardHolderInputPlaceHolder}
        />
      </FormInputContainerWrapper>
    </InputFormActionWrapper>
  );
};

export default CardHolderInput;
