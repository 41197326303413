import { COLORS } from "../../../constants/themeConstants";

const AsquadLoaderIcon = ({ color = COLORS.DEFAULT_WHITE }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0034 17.9978C12.0034 19.1848 11.6514 20.3452 10.9919 21.3321C10.3324 22.3191 9.3951 23.0884 8.29843 23.5426C7.20177 23.9969 5.99502 24.1157 4.83081 23.8841C3.6666 23.6526 2.5972 23.081 1.75785 22.2416C0.9185 21.4023 0.346901 20.3329 0.115325 19.1686C-0.116252 18.0044 0.00259711 16.7977 0.45685 15.701C0.911103 14.6044 1.68036 13.667 2.66733 13.0076C3.6543 12.3481 4.81467 11.9961 6.00169 11.9961H12.0034V17.9978Z"
      fill={color}
    />
    <path
      d="M12.0034 5.99831C12.0034 4.8121 12.3551 3.65252 13.0141 2.66618C13.673 1.67984 14.6096 0.911016 15.7055 0.456919C16.8014 0.00282191 18.0072 -0.116161 19.1707 0.115012C20.3342 0.346185 21.403 0.917134 22.242 1.75568C23.081 2.59422 23.6525 3.6627 23.8844 4.82603C24.1162 5.98937 23.9979 7.19532 23.5444 8.29143C23.0909 9.38754 22.3226 10.3246 21.3367 10.9841C20.3507 11.6436 19.1913 11.996 18.0051 11.9966H12.0034V5.99831Z"
      fill={color}
    />
  </svg>
);

export default AsquadLoaderIcon;
