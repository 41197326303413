import { useContext } from "preact/hooks";
import instructions from "../../assets/configs/instructions";
import {
  BlockDetailsWrapper,
  IconColorWrapper,
  InnerHeaderDataWrapper,
  InnerLine,
} from "../../styles";
import {
  ChallengeInfoWrapper,
  ChallengeMainTitle,
  StyledInfoImage,
} from "../Challenge/style";
import Trans from "../Trans";
import {
  InstructionStepCopyIconWrapper,
  InstructionStepDataText,
  InstructionStepDataWrapper,
  InstructionStepNumber,
  InstructionStepTitle,
  InstructionStepTitleWrapper,
  InstructionStepWrapper,
  InstructionWrapper,
  InstructionStepSecondaryTitle,
  InfoMainTitleContainer,
} from "./style";
import { ContextUI } from "../../providers/UIProvider";
import Tooltip from "../Tooltip";
import IconCopy from "../../assets/icons/iconCopy";
import { Context } from "../../app";
import InfoIcon from "../../assets/icons/infoIcon";
import ExpirationDate from "../BankInfo/ExpirationDate";

type TInstructionConfig = keyof typeof instructions;
type TInstructionData = { [key: string]: string };

const Instruction = ({
  configName,
  instructionData = {},
}: {
  configName: TInstructionConfig;
  instructionData: TInstructionData;
}) => {
  const { currentState } = useContext(Context);
  const { handleCopy } = useContext(ContextUI);
  const config = instructions[configName];

  if (!config) {
    return null;
  }

  const {
    title,
    instructionList,
    getInfo,
    getInfos,
    getInfoTitle,
    getExpirationDate,
    infoBlockHasBottomPosition = true,
  } = config || {};
  const info = getInfo && getInfo({ data: instructionData });
  const infos = getInfos && getInfos({ data: instructionData });
  const infoTitle = getInfoTitle && getInfoTitle();
  const expirationDate =
    getExpirationDate && getExpirationDate({ data: instructionData });

  const renderInfo = () => {
    if (info || infos) {
      return (
        <>
          <BlockDetailsWrapper
            // @ts-ignore
            $mb="0"
            width="100%"
            style={{ justifyContent: "center" }}
          >
            <ChallengeInfoWrapper>
              <StyledInfoImage>
                <InfoIcon />
              </StyledInfoImage>
              {infoTitle ? (
                <InfoMainTitleContainer>
                  <Trans message={infoTitle} />
                  {info && <Trans message={info} />}
                  {infos && !info && infos.map((i) => <Trans message={i} />)}
                </InfoMainTitleContainer>
              ) : (
                <ChallengeMainTitle>
                  {info && <Trans message={info} />}
                  {infos && !info && infos.map((i) => <Trans message={i} />)}
                </ChallengeMainTitle>
              )}
            </ChallengeInfoWrapper>
          </BlockDetailsWrapper>
          {!infoBlockHasBottomPosition ? (
            <InnerLine style={{ marginBottom: 0 }} />
          ) : null}
        </>
      );
    }

    return null;
  };

  return (
    <InnerHeaderDataWrapper
      // @ts-ignore
      $top="0"
      $padding={!title && !instructionList ? "0" : "20px"}
      $gap="16px"
    >
      {!infoBlockHasBottomPosition && renderInfo()}
      {title && (
        <ChallengeMainTitle>
          <Trans message={title} />:
        </ChallengeMainTitle>
      )}

      {instructionList && (
        <InstructionWrapper>
          {instructionList?.map((step, index) => {
            const { title, secondaryTitle, getPaymentData, getCopyInfo } =
              step || {};
            const paymentData =
              getPaymentData &&
              getPaymentData({
                data: instructionData,
                state: currentState || {},
              });
            const { copy, text } =
              (getCopyInfo &&
                getCopyInfo({
                  data: instructionData,
                  state: currentState || {},
                })) ||
              {};

            return (
              <InstructionStepWrapper>
                <InstructionStepTitleWrapper key={index}>
                  <InstructionStepNumber>{index + 1}</InstructionStepNumber>
                  <InstructionStepTitle>
                    <Trans message={title} withoutWrapper />
                    {/* // TODO: Refactor to use a translation function */}
                    {secondaryTitle && (
                      <InstructionStepSecondaryTitle
                        bold={secondaryTitle?.bold}
                      >
                        {" "}
                        <Trans message={secondaryTitle.text} />
                      </InstructionStepSecondaryTitle>
                    )}
                  </InstructionStepTitle>
                </InstructionStepTitleWrapper>
                {paymentData && (
                  <InstructionStepDataWrapper>
                    <InstructionStepDataText>
                      {paymentData}
                    </InstructionStepDataText>
                    {copy && (
                      <InstructionStepCopyIconWrapper
                        onClick={() => handleCopy(copy, text)}
                      >
                        <IconColorWrapper>
                          <Tooltip text={"Сopy"}>
                            <IconCopy />
                          </Tooltip>
                        </IconColorWrapper>
                      </InstructionStepCopyIconWrapper>
                    )}
                  </InstructionStepDataWrapper>
                )}
              </InstructionStepWrapper>
            );
          })}
        </InstructionWrapper>
      )}

      {expirationDate && <ExpirationDate expiration_date={expirationDate} />}
      {infoBlockHasBottomPosition && renderInfo()}
    </InnerHeaderDataWrapper>
  );
};

export default Instruction;
