import { useEffect, useRef, useState } from "preact/hooks";
import { logger, state } from "../services";
import { useConnection } from "../services/hooks";

const useDynamicImport = <T,>({
  fn,
  name,
}: {
  fn: (timestamp?: number) => Promise<{ default: T }>;
  name: string;
}): T | null => {
  const [module, setModule] = useState<T | null>(null);
  const [timestamp, setTimestamp] = useState<number>(() => Date.now());
  const hasConnection = useConnection();
  const isRetrying = useRef(false);
  const hasError = useRef(false);

  const loadModule = async () => {
    if (module || isRetrying.current) return;

    isRetrying.current = true;

    if (name) {
      state.setLoading({
        status: true,
        withBlur: true,
        eventName: `file_import_${name}`,
      });
    }

    try {
      const loadedModule = await fn(
        hasError.current ? timestamp : undefined,
      ).then((module) => module.default);
      setModule(() => loadedModule);
      hasError.current = false;
    } catch (error) {
      hasError.current = true;
      logger.error(`Failed to load module: ${name || "unknown"}`, {
        error,
      });
    } finally {
      isRetrying.current = false;

      if (name) {
        state.setLoading({
          status: false,
          eventName: `file_import_${name}`,
        });
      }
    }
  };

  useEffect(() => {
    loadModule();
  }, []);

  useEffect(() => {
    if (hasConnection && !module && hasError.current) {
      setTimestamp(Date.now());
      loadModule();
    }
  }, [hasConnection, module, hasError.current]);

  return module;
};

export default useDynamicImport;
