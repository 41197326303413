export default [
  "en-GB",
  "es-AR",
  "ja-JP",
  "kk-KZ",
  "ko-KR",
  "pl-PL",
  "pt-BR",
  "ru-RU",
  "tr-TR",
  "uk-UA",
  "az-AZ",
  "de-DE",
  "hu-HU",
  "id-ID",
];
