import InfoIcon from "../../assets/icons/infoIcon";
import BankInfoDetails from "./BankInfoDetails";
import Trans from "../Trans";
import ExpirationDate from "./ExpirationDate";
import {
  TotalInfoWrapper,
  ContainerDetailsWrapper,
  BlockDetailsWrapper,
  InnerHeaderDataWrapper,
} from "../../styles";
import {
  ChallengeInfoWrapper,
  StyledInfoImage,
  ChallengeMainTitle,
} from "../Challenge/style";

const BankInfo = ({
  bankNameIcon,
  bankName,
  bankCode,
  accountName,
  accountNumber,
  handleCopy,
  expiration_date,
  phone,
  receiverName,
  hideInfo,
  mainTitle,
}) => {
  const data = [
    {
      title: "accountNumber",
      value: accountNumber,
      copyMessage: "accountNumberCopied",
    },
    {
      title: "phone",
      value: phone,
      copyMessage: "phoneCopied",
    },
    {
      title: "receiverName",
      value: receiverName,
      copyMessage: "receiverNameCopied",
    },
    {
      title: "bankName",
      value: bankName,
      copyMessage: "bankNameCopied",
      bankNameIcon,
    },
    {
      title: "bankCode",
      value: bankCode,
      copyMessage: "bankCodeCopied",
    },
    {
      title: "accountName",
      value: accountName,
      copyMessage: "accountNameCopied",
    },
  ];

  return (
    <InnerHeaderDataWrapper $top="0" $padding="20px">
      <ChallengeMainTitle data-transaction-name="bank-info-title">
        <Trans message={mainTitle} />:
      </ChallengeMainTitle>

      <TotalInfoWrapper>
        <ContainerDetailsWrapper style={{ marginTop: "16px" }}>
          {data?.map((item, index) => (
            <BankInfoDetails
              key={index}
              title={item?.title}
              value={item?.value}
              copyMessage={item?.copyMessage}
              handleCopy={handleCopy}
              bankNameIcon={item?.bankNameIcon}
            />
          ))}

          {expiration_date && (
            <ExpirationDate expiration_date={expiration_date} />
          )}

          {!hideInfo && (
            <BlockDetailsWrapper $mb="0" style={{ justifyContent: "center" }}>
              <ChallengeInfoWrapper>
                <StyledInfoImage>
                  <InfoIcon />
                </StyledInfoImage>
                <ChallengeMainTitle>
                  <Trans message="virtualAccountFooter" />
                </ChallengeMainTitle>
              </ChallengeInfoWrapper>
            </BlockDetailsWrapper>
          )}
        </ContainerDetailsWrapper>
      </TotalInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default BankInfo;
