/* eslint-disable no-use-before-define */
import { useState, useEffect, useContext } from "preact/hooks";
import { state, processing, i18n, theme, logger } from "../services";
import { CONST_ERROR_LIST } from "../services/constants";
import Trans from "./Trans";
import CardListModal from "./Modals/CardListModal";
import inputCardControl from "./InputCard/InputCardControl";
import iconCardControl from "./IconCard/IconCardControl";
import {
  InputFormActionWrapper,
  FormDescription,
  FormInputContainerWrapper,
  FormErrorWrapper,
  FormErrorTitleCvv,
} from "../styles";
import { ContextUI } from "../providers/UIProvider";

const INITIAL_VALUES = {
  card: "",
  month: "",
  year: "",
  cvv: "",
  date: "",
  cardHolder: "",
  brand: "",
};

const InputCard = ({
  values,
  setValues,
  errors,
  setErrors,
  nextStep,
  cvvStep,
  cardHolderStep,
  checkedCard,
  setCheckedCard,
  hiddenRememberMe,
  themeConfig,
  setRememberMe,
}) => {
  const [focused, setFocused] = useState(false);

  const { setModalData, handleCloseModal } = useContext(ContextUI);

  const cardList = state.get().cards || [];
  const options = state.get().options;
  const currentTheme = theme.getThemeName(options?.theme);
  const ControlInputCard =
    inputCardControl[currentTheme] || inputCardControl.default;
  const IconCardControl =
    iconCardControl[currentTheme] || iconCardControl.default;
  const conditionForShowArrow =
    cardList &&
    Array.isArray(cardList) &&
    cardList.filter((e) => e.enabled).length > 0 &&
    !hiddenRememberMe;

  const acceptedCardLengths = [17, 18, 19, 21, 22];

  useEffect(() => {
    if (hiddenRememberMe) {
      setValues((prev) => ({
        ...prev,
        ...INITIAL_VALUES,
      }));
    }
  }, [hiddenRememberMe]);

  useEffect(() => {
    if (
      cardList &&
      Array.isArray(cardList) &&
      cardList.length > 0 &&
      !checkedCard
    ) {
      handleCheckSavedCard(cardList[0]);
    }
  }, [JSON.stringify(cardList)]);

  useEffect(() => {
    if (!ControlInputCard || !IconCardControl) {
      logger.error("Component not found");
    }
  }, []);

  const onCardInput = (e) => {
    let val = e.target.value.replace(/[^\dA-Z]/g, "").trim();

    if (checkedCard && val !== values.card) {
      setCheckedCard(null);
      setRememberMe(null);
      val = "";
      setValues((prev) => ({
        ...prev,
        card: "",
        month: "",
        year: "",
        cvv: "",
        date: "",
        cardHolder: "",
        brand: "",
      }));

      setErrors({});
    }

    val = val
      .replace(/^(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2");

    const brand = processing.detectCardBrand(val);

    if (acceptedCardLengths.includes(val.length)) {
      if (!processing.checkLuhn(val)) {
        setErrors((prev) => ({
          ...prev,
          card: CONST_ERROR_LIST.wrong_card,
        }));
      } else {
        setErrors((prev) => {
          delete prev.card;

          return prev;
        });

        setTimeout(() => {
          nextStep && nextStep.current && nextStep.current.focus();
        }, 0);
      }
    }

    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;

    target.value = target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;

    setValues((prev) => ({
      ...prev,
      card: val,
      brand:
        acceptedCardLengths.includes(val.length) && processing.checkLuhn(val)
          ? brand
          : "",
    }));
  };

  const onCardBlur = () => {
    setFocused(false);

    if (checkedCard) return setErrors({});

    if (values.card.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        card: CONST_ERROR_LIST.required,
      }));
    }

    if (
      !acceptedCardLengths.includes(values.card.length) ||
      (acceptedCardLengths.includes(values.card.length) &&
        !processing.checkLuhn(values.card))
    ) {
      return setErrors((prev) => ({
        ...prev,
        card: CONST_ERROR_LIST.wrong_card,
      }));
    }

    return setErrors((prev) => {
      delete prev.card;

      return prev;
    });
  };

  const errorChecking = (name) => {
    if (errors[name]) {
      if (focused) {
        if (name === "card") {
          acceptedCardLengths.includes(values.card.length);
        }

        return true;
      }

      return true;
    }

    return false;
  };

  const handleCheckSavedCard = (card) => {
    if (card) {
      setCheckedCard(card);
      setValues((prev) => ({
        ...prev,
        card: `${card.bin?.slice(0, 4)} ${card.bin?.slice(4, 6)}** **** ${
          card.last4
        }`,
        date: `${card.exp_month}${
          card.exp_year?.length === 4
            ? card.exp_year?.slice(2, 4)
            : card.exp_year
        }`,
        cvv: "",
        brand: card.brand,
        cardHolder: card.holder_name,
      }));

      handleCloseModal();
      setErrors({});

      setTimeout(() => {
        cvvStep?.current?.focus();
      }, 200);
    }
  };

  const handleRemoveSavedCard = (identifier) => {
    if (checkedCard && checkedCard.identifier === identifier) {
      setCheckedCard(null);

      setValues((prev) => ({
        ...prev,
        ...INITIAL_VALUES,
      }));

      setErrors({});
    }

    setTimeout(() => {
      handleCloseModal();

      cardHolderStep?.current?.focus();
    }, 200);
  };

  const generateIconCard = (value) => {
    if (!hiddenRememberMe || themeConfig?.cardIconPass) {
      return <IconCardControl value={value} values={values} />;
    }

    return null;
  };

  const openModal = () => {
    setModalData({
      isOpen: true,
      content: (
        <CardListModal
          handleCheckSavedCard={handleCheckSavedCard}
          handleRemoveSavedCard={handleRemoveSavedCard}
          checkedCard={checkedCard}
        />
      ),
    });
  };

  return (
    <InputFormActionWrapper>
      <FormDescription
        $errorChecking={errorChecking("card")}
        $focused={focused}
      >
        <Trans message={themeConfig?.inputCardLabel} />
      </FormDescription>

      <FormInputContainerWrapper>
        <ControlInputCard
          errorChecking={errorChecking}
          focused={focused}
          checkedCard={checkedCard}
          conditionForShowArrow={conditionForShowArrow}
          onCardBlur={onCardBlur}
          values={values}
          onCardInput={onCardInput}
          setFocused={setFocused}
          options={options}
          generateIconCard={generateIconCard}
          openModal={openModal}
          errors={errors}
          placeholder={currentTheme?.cardInputPlaceHolder}
          themeName={options?.theme}
          theme={currentTheme}
        />
      </FormInputContainerWrapper>
      {errorChecking("card") && (
        <FormErrorWrapper>
          <FormErrorTitleCvv>
            {i18n.getMessage({ message: errors.card })}
          </FormErrorTitleCvv>
        </FormErrorWrapper>
      )}
    </InputFormActionWrapper>
  );
};

export default InputCard;
