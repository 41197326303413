import { PAYMENT_METHODS } from "../../../constants/paymentMethods";
import { IPaymentInstructions } from "./interfaces";

const bankCodeChallenge: IPaymentInstructions = {
  getInfoTitle: () => "initiatedAmountDeclinedTitle",
  getInfos: ({ data }) => {
    if (data?.currentMethod === PAYMENT_METHODS.BANK_TRANSFER_JAPAN) {
      return ["initiatedAmountDeclined", "initiatedAmountDeclined2"];
    }

    return null;
  },
};

export default bankCodeChallenge;
