import test2 from "./test2";

const test = () => {
  if (!test2) {
    return () => {};
  }

  return test2();
};

export default { test };
